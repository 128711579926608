export function updateIframeScrollTo(value) {
	const win: any = window
	win.parent.postMessage({ scrollAnchor: value }, "*")

	//console.log({ scrollAnchor: value })
}
export function updateIframeHeight(key?) {
	try {
		const win: any = window
		const doc: any = document
		const body = doc.body
		const height = body.scrollHeight

		win.parent.postMessage({ iframeHeight: height }, "*")
		//console.log({ iframeHeight: height }, key)
	} catch (error) {
		// Fehlerbehandlung hier
	}
}
