import { useEffect } from "react"
import { Link } from "react-router-dom"
import UpScroll from "../components/Upscroll"

function ConfirmWithoutCEP() {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "Confirm", link: label })
		} catch (e) {}
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<section>
			<div className="bg-blue py-2 -mt-6 mb-6 lg:-mt-24 lg:mb-12 pt-[32px] lg:pt-[90px] pb-[150px] white-brush-border-bottom">
				<div className="text-center mx-auto px-6 lg:px-2 py-4 lg:pt-24">
					<h2 className="h2 max-w-[750px] mx-auto text-[32px] lg:text-[50px] font-bold font-FireSansCondensedExtraBold uppercase">
						Vielen Dank für Deine Teilnahme an unserer Aktion.
					</h2>
					<div className="grid grid-cols-1 lg:grid-cols-[42%_58%] max-w-[750px] mx-auto text-center lg:text-left mt-12 lg:mt-20">
						<img src={process.env.PUBLIC_URL + "/images/knoppers/tasse-lukas.png"} className="max-w-[250px] ml-14 lg:ml-0 lg:mr-12" />
						<div>
							<p className="text-[16px] lg:text-[20px] text-white mt-12 lg:mt-4 font-normal font-FireSansRegular leading-normal">
								Deine Bestellung wird nun bearbeitet. Wenn Deine Bestellung unseren Teilnahmebedingungen entspricht, wird Deine Knoppers Tasse innerhalb von ca. 8 Wochen an die von
								Dir angegebene Adresse geschickt.
							</p>
							<p className="text-[16px] lg:text-[20px] text-white mt-4 font-normal font-FireSansRegular leading-normal">
								Du erhältst eine Versandbestätigung per E-Mail sobald die Tasse auf dem Weg zu Dir ist.
							</p>
						</div>
					</div>
					<p className="text-[16px] lg:text-[20px] text-white font-normal font-FireSansBold leading-normal mt-12 lg:mt-24 text-center">
						Wir wünschen Dir viel Freude mit Deiner personalisierten Knoppers Tasse!
					</p>
					<p className="text-[16px] lg:text-[20px] text-white font-normal font-FireSansBold leading-normal mt-6 lg:mt-12 mb-12 text-center">Dein Knoppers Team</p>
				</div>
				<>
					<div className="">
						<div className="max-w-md mx-auto">
							<div className="my-6 text-center">
								<a href="/">
									<button
										className="btn-nosize-red uppercase max-w-[200px] lg:max-w-[300px]"
										onClick={() => {
											track("Zurück zur Startseite")
										}}
									>
										Weitere Tasse bestellen
									</button>
								</a>
							</div>
						</div>
					</div>
				</>
			</div>
			<div className="mt-24 lg:mt-24 mb-12 lg:mb-24">
				<UpScroll />
			</div>
		</section>
	)
}
export default ConfirmWithoutCEP
