import { injectIntoForm } from "./AZ"

const PrefillForm = () => {
	return (
		<>
			{process.env.NODE_ENV === "development" && (
				<button
					className="btn"
					onClick={() => {
						setTimeout(() => {
							injectIntoForm("code1", "XXXXXXXXXX")
						}, 100)
						setTimeout(() => {
							injectIntoForm("code2", "YYYYYYYYYY")
						}, 100)
						setTimeout(() => {
							injectIntoForm("code3", "ZZZZZZZZZZ")
						}, 100)
						setTimeout(() => {
							injectIntoForm("code4", "WWWWWWWWWW")
						}, 100)
						setTimeout(() => {
							injectIntoForm("code5", "QQQQQQQQQQ")
						}, 100)
						setTimeout(() => {
							injectIntoForm("email", "asdf@asdf.de")
						}, 500)
						setTimeout(() => {
							injectIntoForm("firstname", "firstname")
						}, 1100)
						setTimeout(() => {
							injectIntoForm("lastname", "lastname")
						}, 1700)
						setTimeout(() => {
							injectIntoForm("zip", "25454")
						}, 2300)
						setTimeout(() => {
							injectIntoForm("city", "Die Stadt")
						}, 2900)
						setTimeout(() => {
							injectIntoForm("street", "Beste Straße")
						}, 3500)
						setTimeout(() => {
							injectIntoForm("housenr", "99")
						}, 4000)
					}}
				>
					+++ prefill form +++
				</button>
			)}
		</>
	)
}

export default PrefillForm
