import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	hasError?: boolean
	azMarkup?: any
}

const ElemSelect: React.FC<InputProps> = ({ className, props, label, errMsg, hasError, children, azMarkup }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			{azMarkup}
			<select
				className={
					"pt-4 shadow-[inset_0_2px_2px_0_rgba(0,0,0,0.15)]  w-full peer rounded-[24px] px-4 text-[14px] font-Manrope text-black h-[60px] leading-[53px] placeholder-transparent outline-0 bg-gray appearance-none bg-right bg-no-repeat bg-[url('/src/bgimages/icon-dropdown.png')] " +
					(hasError ? "  js-formerror " : "")
				}
				{...props}
			>
				{children}
			</select>
			<span
				className={
					(!!props.value ? "-translate-y-2" : "") +
					" -translate-y-2 transition absolute left-0 top-0 font-Soleil text-[14px] px-4 h-[60px] leading-[60px]" +
					(hasError ? "  text-red " : " text-brown ")
				}
			>
				{label}
			</span>
			<div ref={animationRef}>{hasError && <div className="formErrorMsg ">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemSelect
