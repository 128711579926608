import React, { useContext, useEffect, useState } from "react"
import CityName from "../components/configurator/CityName"
import Overview from "../components/configurator/Overview"
import Popup from "../components/elements/Popup"
import { STEP } from "../config"
import { Context } from "../store/store"

const Configurator: React.FC = () => {
	const { globalState, dispatch } = useContext(Context)
	const [currStep, setCurrStep] = useState<STEP>(STEP.CITYNAME)
	const [popUpOpen, setPopUpOpen] = useState(false)

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({
				event: "raffle",
				rafflestatus: "start",
				category: "Opt_Raffle",
				action: "Raffle",
				label: "Start",
			})
		} catch (e) {}
	}, [currStep])

	return (
		<>
			<section className="bg-no-repeat bg-[50%] bg-top mb-12">
				<div className="pt-0">
					{currStep === STEP.CITYNAME && <CityName setCurrStep={newStep => setCurrStep(newStep)} activeStep={currStep} />}
					{currStep === STEP.OVERVIEW && <Overview setCurrStep={newStep => setCurrStep(newStep)} />}
				</div>
			</section>
			{popUpOpen && (
				<Popup
					close={() => {
						setPopUpOpen(false)
						window.location.pathname = "/de/de/xp/snackbox/"
						return
					}}
					btnStyle="btn"
					btnLabel="Zurück zur Startseite"
				>
					<h3 className="gutter-bottom">Zu wenig Punkte!</h3>
					<p className="mb-4">Um deine Snackbox zu gestalten benötigst du mindestens 10 Punkte</p>
				</Popup>
			)}
		</>
	)
}
export default Configurator
