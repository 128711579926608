const SvgFontElement = ({ name }) => {
    return (
        <svg className="svg-class-1" width="350" height="120" viewBox="0 0 350 75">
            <rect x="0" y="0" width="350" height="75" className="svg-class-2" /><g className="svg-class-3"><text x="175" y="55" className="svg-class-4" textRendering="geometricPrecision" strokeLinecap="round" >
                {name}
            </text><text x="175" y="55" className="svg-class-5" textRendering="geometricPrecision" >
                    {name}
                </text><text x="175" y="55" className="svg-class-6" textRendering="geometricPrecision" >
                    {name}
                </text></g>
        </svg>
    )
}

export default SvgFontElement