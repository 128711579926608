import { useEffect } from "react"
import { Link } from "react-router-dom"

const Terms = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "Teilnahmebedingungen", link: label })
		} catch (e) {}
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<section className="headerAlignment">
			<div className="container px-4 lg:px-8 max-w-4xl">
				<h1 className="h2 pb-8 text-[24px] mt-12 lg:mt-4 text-darkblue">TEILNAHMEBEDINGUNGEN KNOPPERS TASSEN-AKTION 2024</h1>
				<p className="">
					Die AUGUST STORCK KG, Waldstraße 27, 13403 Berlin (im Folgenden „STORCK“) veranstaltet vom 06.02.2024 bis 21.05.2024 die Aktion „Knoppers Tasse“ in Deutschland und
					Österreich. Mit der Teilnahme an der Aktion erklärt sich der Teilnehmer mit diesen Teilnahmebedingungen einverstanden.
				</p>

				<h3 className="h3 my-4 text-darkblue">1. TEILNAHMEBERECHTIGUNG</h3>
				<ol>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Teilnahmeberechtigt ist jede natürliche Person ab 18 Jahren innerhalb Deutschlands und Österreichs. Für die Teilnahme ist ein Internetzugang erforderlich. Insgesamt
						fünf Teilnahmecodes berechtigen zur Teilnahme an der Aktion. Mehrfachteilnahmen mit weiteren Codes sind möglich.
					</li>
				</ol>

				<h3 className="h3 my-4 text-darkblue">2. TEILNAHMEZEITRAUM</h3>
				<ol>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Der Teilnahmezeitraum, in dem die Aktionscodes unter{" "}
						<Link to="/" className="underline" target="_blank" rel="noopener noreferrer" onClick={() => track("Knoppers Tasse Webseite")}>
							www.knoppers-tasse.de
						</Link>{" "}
						eingelöst werden können, beginnt am 06.02.2024 und endet mit Ablauf des 21.05.2024. Danach verlieren die Aktionscodes ihre Gültigkeit.
					</li>
				</ol>

				<h3 className="h3 my-4 text-darkblue">3. AKTIONSPACKUNGEN</h3>
				<ol>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Die Aktionspackungen sind durch einen deutlichen Hinweis auf die Aktion klar zu erkennen. Die Aktionscodes befinden sich auf der Innenseite der Aktionspackungen von
						Knoppers Classic 8er, Knoppers Black & White 8er, Knoppers Erdnuss 8er und Knoppers Joghurt 8er. Um eine personalisierte Tasse im Knoppers Design zu bestellen, ist der
						Kauf von fünf Knoppers Aktionspackungen erforderlich. Abbildungen und Informationen zu den teilnehmenden Aktionspackungen können unter{" "}
						<Link to="/" className="underline" target="_blank" rel="noopener noreferrer" onClick={() => track("Knoppers Tasse Webseite")}>
							www.knoppers-tasse.de
						</Link>{" "}
						eingesehen werden.
					</li>
				</ol>

				<h3 className="h3 my-4 text-darkblue">4. PRÄMIE</h3>
				<ol>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Bei der Prämie handelt es sich um eine Porzellantasse mit Henkel. Die Tasse ist mit dem Spruch „Läuft bei dir“ und dem Wunschnamen bedruckt. Eine beispielhafte
						Abbildung der Prämie kann unter{"  "}
						<Link to="/" className="underline" target="_blank" rel="noopener noreferrer" onClick={() => track("Knoppers Tasse Webseite")}>
							www.knoppers-tasse.de
						</Link>{" "}
						eingesehen werden.{" "}
					</li>
				</ol>
				<h3 className="h3 my-4 text-darkblue">5. TEILNAHME UND ABLAUF</h3>
				<ol>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Die Teilnahme an der Aktion ist ausschließlich online unter{" "}
						<Link to="/" className="underline" target="_blank" rel="noopener noreferrer" onClick={() => track("Knoppers Tasse Webseite")}>
							www.knoppers-tasse.de
						</Link>{" "}
						möglich. Zur Teilnahme an der Aktion berechtigt der Kauf von fünf Knoppers Aktionspackungen im Aktionszeitraum vom 06.02.2024 bis zum 21.05.2024.
					</li>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">Jede Aktionspackung enthält einen Teilnahmecode auf der Innenseite der Aktionspackung.</li>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Wenn der Teilnehmer insg. 5 Codes gesammelt hat, kann die Tasse im Aktionszeitraum vom 06.02.2024 bis zum 21.05.2024 online unter{" "}
						<Link to="/" className="underline" target="_blank" rel="noopener noreferrer" onClick={() => track("Knoppers Tasse Webseite")}>
							www.knoppers-tasse.de
						</Link>{" "}
						wie folgt bestellt werden:
						<br />
						<ol className="mt-4">
							<li className="mt-4">
								1. Eingabe von einem Namen oder Kosenamen mit einer Zeichenlänge von max. 12 Zeichen (inkl. Leer- und Sonderzeichen) zur Personalisierung der Tasse
							</li>
							<li className="mt-4">
								2. Eingabe der fünf Aktionscodes und persönlichen Daten (Vor- und Nachname, Postadresse in Deutschland oder Österreich, E-Mail-Adresse) zur Auslieferung gratis per
								Post
							</li>
							<li className="mt-4">3. Bestätigung der Angaben mit einem Klick auf „Jetzt gratis bestellen“</li>
						</ol>
					</li>

					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Auf der Aktionsseite wird vor Bestellabschluss eine Code-Verifizierung durchgeführt, welche prüft, ob die Aktionscodes gültig sind. Jeder Aktionscode kann nur für einen
						Bestellvorgang genutzt werden (nach Verwendung des Aktionscodes verliert dieser seine Gültigkeit).
					</li>

					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Der Teilnehmer ist für die Vollständigkeit und Richtigkeit der angegebenen Kontaktdaten, insbesondere seiner E-Mail- und Postadresse, selbst verantwortlich.{" "}
					</li>

					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Der Teilnehmer steht dafür ein, dass eingesandte Texte keine Rechte Dritter (Persönlichkeits-, Urheber- und sonstige Schutzrechte) verletzen und stellt STORCK
						diesbezüglich umfassend von möglichen Ansprüchen Dritter frei. Das Verwenden rechtsverletzender, sittenwidriger oder anderweitig unzulässiger oder anstößiger Inhalte
						ist nicht gestattet. STORCK behält sich das Recht vor, Texte nach eigenem Ermessen abzulehnen. Das gilt insbesondere dann, wenn die Texte einen beleidigenden,
						jugendgefährdenden, rassistischen, sexistischen, das Produkt oder die Marke betreffend negativen oder anderweitig diskriminierenden Inhalt haben, die gegen
						Gesetzesvorschriften, fremde Rechte oder diese Teilnahmebedingungen verstoßen.
					</li>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Alle eingegebenen Namen werden auf die oben genannten Kriterien hin überprüft. Bei Eingabe eines uns unbekannten Namens, der nicht direkt bestätigt werden kann, wird
						die Eingabe nach Abschluss der Bestellung geprüft. Nach erfolgter Prüfung erhält der Teilnehmer eine E-Mail, in der er über die Prüfentscheidung in Kenntnis gesetzt
						wird. Die Prüfung des Wunschnamens kann bis zu 2 Werktage in Anspruch nehmen. Im Falle einer unvorhersehbar hohen Anzahl an Anfragen kann sich die Prüfzeit verzögern.
						Wird der eingegebene Name aus der Bestellung abgelehnt, hat der Teilnehmer die Möglichkeit diesen durch einen anderen Namen zu ersetzen. Alle fünf Aktionscodes aus der
						Bestellung werden hierfür wieder freigeschaltet und der Bestellprozess muss noch einmal durchgeführt werden.
					</li>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						In jedem Fall erhält der Teilnehmer nach Abschluss der Bestellung eine Bestellbestätigung. Der angegebene Name kann nach gültigem Bestellabschluss nicht mehr geändert
						werden.
					</li>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Nach Abschluss des Bestellvorgangs wird die Knoppers Tasse produziert und an die angegebene Adresse kostenfrei innerhalb von 8 Wochen per Post versendet. Die Anschrift
						muss innerhalb Deutschlands oder Österreichs liegen. Ein Versand an eine Packstation oder ein Postfach ist aus abwicklungstechnischen Gründen leider nicht möglich. Eine
						entsprechende Versandbestätigung wird per E-Mail versandt.
					</li>

					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						Für die Aktionsabwicklung werden im Rahmen der Promotion personenbezogene Daten (Vorname, Name, Anschrift, E-Mail-Adresse) abgefragt. Bei den Pflichtangaben handelt es
						sich um notwendige Angaben, um dem Teilnehmer die personalisierte Knoppers Tasse zu schicken und ihn bei einer eventuell anfallenden individuellen Namensprüfung oder
						anderen Rückfragen kontaktieren zu können. Über die angegebene E-Mail-Adresse wird der Teilnehmer zudem über den Bestell- und Lieferstatus seiner Bestellung informiert.
					</li>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						STORCK ist berechtigt, einzelne Personen von der Teilnahme auszuschließen, sofern berechtigte Gründe vorliegen, wie z.B. der Verstoß gegen die Teilnahmebedingungen oder
						eine Manipulation. Teilnehmer, bei denen der Verdacht auf einen vorsätzlichen oder fahrlässigen Missbrauch der Aktion oder auf die Angaben falscher, irreführender oder
						betrügerischer Daten besteht, werden von der Teilnahme ausgeschlossen. STORCK behält sich rechtliche Schritte gegen diese Teilnehmer vor.
					</li>
					<h3 className="h3 my-4 text-darkblue">6. ÄNDERUNGEN ODER VORZEITIGE BEENDIGUNG</h3>
					<li className="lg:pl-4 lg:ml-6 mb-4 ">
						STORCK behält sich vor, diese Teilnahmebedingungen jederzeit abzuändern und die Aktion ohne Angaben von Gründen für einen vorübergehenden Zeitraum auszusetzen oder
						vorzeitig zu beenden, insbesondere wenn die ordnungsgemäße Durchführung aus technischen, rechtlichen oder sonstigen Gründen nicht mehr gewährleistet werden kann.
						Hieraus entstehen keine Ansprüche gegen den Veranstalter. Die Teilnahmebedingungen sind in ihrer jeweils aktuellen Fassung online abrufbar.
					</li>
					<h3 className="h3 my-4 text-darkblue">7. DATENSCHUTZ </h3>
					<li className="lg:lg:pl-4 lg:ml-6 mb-4 ">
						STORCK nutzt die im Rahmen der Aktion „Knoppers Tasse“ verarbeiteten personenbezogenen Daten ausschließlich zum Zwecke der Durchführung und Abwicklung der Aktion. Eine
						weitergehende Nutzung durch STORCK oder Dritte findet nicht statt. Im Rahmen der Aktion „Knoppers Tasse“ arbeitet STORCK mit seiner Tochtergesellschaft STORCK
						Deutschland KG, Waldstraße 27, 13403 Berlin, sowie dem sorgfältig ausgewählten Dienstleister direct services Gütersloh GmbH, Reinhard-Mohn-Straße 300, 33333 Gütersloh
						zusammen. Die für STORCK tätigen Auftragsverarbeiter sind ordnungsgemäß auf die Einhaltung des Datenschutzes verpflichtet. Weitere Informationen zum Datenschutz findest
						du unter{" "}
						<Link to="/datenschutz" className="underline" target="_blank" rel="noopener noreferrer" onClick={() => track("Datenschutz")}>
							www.knoppers-tasse.de/datenschutz
						</Link>
						.
					</li>
					<h3 className="h3 my-4 text-darkblue">8. SONSTIGE BESTIMMUNGEN</h3>
					<li className="lg:lg:pl-4 lg:ml-6 mb-12">
						Die Aktion „Knoppers Tasse“ unterliegt ausschließlich dem Recht der Bundesrepublik Deutschland. Sollten einzelne dieser Bestimmungen ungültig sein oder werden, bleibt
						die Gültigkeit der übrigen Bestimmungen hiervon unberührt. Der Weiterverkauf der Tassen und/oder der Aktionscodes ist untersagt. Barauszahlung und Umtausch sind nicht
						möglich.
						<br /> <br /> Das Service-Team ist von Montag bis Freitag von 08:30-17:30 Uhr telefonisch unter +49 (0)30 / 47 06 00 68 oder per E-Mail unter{" "}
						<a href="mailto:mail@knoppers-tasse.de" className="underline" rel="noopener noreferrer" onClick={() => track("Email")}>
							mail@knoppers-tasse.de
						</a>{" "}
						zu erreichen.
					</li>
				</ol>
			</div>
		</section>
	)
}

export default Terms
