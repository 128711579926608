import { useAutoAnimate } from "@formkit/auto-animate/react"

interface CheckboxProps {
	props?: any
	className?: string | null
	label?: any
	errMsg?: string
	hasError?: boolean
}

const ElemCheckbox: React.FC<CheckboxProps> = ({ className, props, label, errMsg, hasError }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block cursor-pointer mb-4 px-4 " + (className || "")}>
			<input className={"w-1 h-1 hidden absolute peer "} {...props} />
			<img src={process.env.PUBLIC_URL + "/images/checkbox-icon.svg"} alt={"not selected"} loading="lazy" className="peer-checked:hidden block absolute w-[20px] mr-[20px]" />
			<img src={process.env.PUBLIC_URL + "/images/icon-checkbox-checked.svg"} alt={"selected"} loading="lazy" className="peer-checked:block hidden absolute w-[20px] mr-[20px]" />
			<div className="pl-8 p-link">{label}</div>
			<div ref={animationRef}>{hasError && <div className="formErrorMsg  js-formerror pl-8 ">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemCheckbox

// https://play.tailwindcss.com/tKldzVn3U1
