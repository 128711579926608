export enum ERRORTYPES {
	FORMVALIDATION = "Bitte fülle alle Felder korrekt aus.",
	FACEDETECTION = "Das Bild darf keine Gesichter enthalten.",
	INVALID = "Leider stimmt etwas mit Deinem Kassenbon nicht. Wir können keinen Kauf eines teilnehmenden Produktes erkennen.",
	IMAGECROPPING = "Beim Zuschneides deines Bildausschnittes ist leider ein Fehler aufgetreten. ",
	RETRY = "Du hast Deinen Kassenbon bereits verwendet. Bitte nutze einen anderen Kassenbon. ",
	FILESIZE = "Dein ausgewähltes Foto entspricht nicht den Vorgaben. JPG oder PNG / max. 5 MB",
	TERMSUPLOAD = "Du hast noch keinen Kassenbon hochgeladen oder die Teilnahmebedingungen noch nicht bestätigt. ",
	DOUBBLED = "Du hast Deinen Kassenbon bereits verwendet.",
	MAXTRIES = "Du hast heute bereits zu häufig an unserem Gewinnspiel teilgenommen. Bitte probiere es morgen noch einmal.",
	SERVER = "Bei der Übertragung Deiner Daten ist ein Fehler aufgetreten. Bitte versuche es erneut. Sollten die Probleme weiterhin bestehen, kannst Du Dich an unser Service-Team wenden. Du erreichst uns per E-Mail unter snackbox@nutella.de. Bitte entschuldige die Unannehmlichkeiten.",
}

export enum BLACKLIST {
	NONE = "NONE",
	EMPTY = "EMPTY",
	ALLOWED = "ALLOWED",
	UNKNOWN = "UNKNOWN",
	BLOCKED = "BLOCKED",
}
export const enum STEP {
	CITYNAME,
	SELECTCOUNTRY,
	CODES,
	OVERVIEW,
}

export const CITY_NAME_MAXLENGTH = 15

export const enum CITY_NAME_POS {
	TOP = "TOP",
	MIDDLE = "MIDDLE",
	BOTTOM = "BOTTOM",
}

export const ICONS = ["none", "herz", "glasNutella", "glasHerz", "kamera", "n"]

export const ICONTRANSLATION = {
	iconOne: "Icon 1",
	iconTwo: "Icon 2 - oben links",
	iconThree: "Icon 3 - oben rechts",
}

export const POINTCALCULATIONSTRINGSUSER = {
	nutella_450: 3,
	nutella_750: 4,
	nutella_b_ready: 2,
	nutella_biscuits: 3,
	nutella_go: 1,
}
export const POINTCALCULATIONSTRINGS = {
	nutella_450: 3,
	nutella_750: 4,
	nutella_b_ready: 2,
	nutella_biscuits: 3,
	nutella_go: 1,
	newsletter: 2,
}
export const INPUTSTATES = { true: 1, false: 2 }

export const DESIGNPATTERNS = { 0: "black-white", 1: "plain", 2: "colorful" }
export const INPUTERRORTYPES = {
	duplicate: "Dieser Code wurde bereits verwendet!",
}

export const PASSWORDRESETERROR = {
	"Password needs to be at least 8 characters": "Das Passwort benötigt mindestens 8 Zeichen",
	"Invalid hash token": "Der Passwort zurücksetzen Link ist leider nicht mehr gültig. Bitte vordere einen neuen an.",
	"Password reset successfully": "Das Passwort wurde erfolgreich zurückgesetzt.",
}

export const RECAPTCHAKEY = "6LeOgMEmAAAAAG2Mx4OwlmqhBoufMpL9g1b-3LKQ"

export const AZReferer = "restful.ferrero.az"
export const AZUsername = "ferr81g5-arva-5572-t3cv-75h5ap061k2u"
export const AZPassword = "j739pde2-k64k-7939-oo80-38je2gh5pt3a"
export const AzProdDomains = ["www.knoppers-tasse.de/", "prod.fe.knoppers-tasse.campaign-loyalty.com/", "int.fe.knoppers-tasse.campaign-loyalty.com/", "knoppers-tasse.de/"]

export const MaxCodeLetters = 10

export const HEROSLIDERIMAGES = ["/images/boxSkribble.png", "/images/boxNutella.png", "/images/boxDeutschland.png"]

export const LOWERCASEWORDS = ["und"]

export const navigation = [
	{
		title: "So geht's",
		url: "/#sogehts",
		navIdent: "/",
	},
	{
		title: "Teilnahmebedingungen",
		url: "/teilnahmebedingungen",
		navIdent: "/teilnahmebedingungen",
	},
	{
		title: "FAQ",
		url: "/faq",
		navIdent: "/faq",
	},
	{
		title: "Kontakt",
		url: "/kontakt",
		navIdent: "/kontakt",
	},
]

export const subnavigation = [
	{
		title: "FAQ",
		url: "/faq",
	},
	{
		title: "Teilnahmebedingungen",
		url: "/teilnahmebedingungen",
	},
	{
		title: "Kontakt",
		url: "/kontakt",
	},
]

export const countrys = [
	{
		name: "Deutschland",
		code: "de",
	},
	{
		name: "Österrreich",
		code: "at",
	},
]

export const countryTranslation = {
	de: "Deutschland",
	at: "Österrreich",
}
