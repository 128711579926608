import { getEnvConfig } from "../../env"
import axios from "axios"

/**
 *
 */
export const scrollToErrorField = () => {
	setTimeout(() => {
		let errField = document.getElementsByClassName("js-formerror")
		if (errField.length > 0) {
			const firstErrorField: any = errField[0]
			window.scrollTo({
				top: window.pageYOffset + firstErrorField.getBoundingClientRect().top - 200,
				left: 0,
				behavior: "smooth",
				// top: firstErrorField.offsetTop, left: 0, behavior: 'smooth'
			})
		}
	}, 50)
}

/**
 * Check if all fields are valid (or: if ANY field is invalid).
 * @returns true || false
 */
export const allFieldsValid = (formDefinition, globalState, validation, isCEPMandatory, cepStatus, nameErrorStates) => {
	let allFieldsValid = true
	let fieldsToSkip = ["googleAutocomplete"]

	for (let i = 0; i < Object.keys(formDefinition).length; i++) {
		if (fieldsToSkip.indexOf(formDefinition[i].name) < 0) {

			if (formDefinition[i].name === "dateofbirth" || formDefinition[i].name === "email" || formDefinition[i].name === "salutation") {
				// special handling for email (if not null, validation needed)
				if (
					formDefinition[i].name === "email" &&

					(globalState.userNameStatus == "UNKNOWN" || globalState.userNameStatus == "BLOCKED" || cepStatus.allMarketingConsent || cepStatus.emailMarketingConsent) &&
					validation["isInvalid_email"]

				) {
					allFieldsValid = false

					// console.log("validation failed for: " + formDefinition[i].name )
					break
				}

				// special handling for date of birth
				if (isCEPMandatory && validation["isInvalid_" + formDefinition[i].name]) {
					allFieldsValid = false

					// console.log("validation failed for: " + formDefinition[i].name )
					break
				}
			} else if (validation["isInvalid_" + formDefinition[i].name]) {
				allFieldsValid = false
				break
			}
		}
	}

	return allFieldsValid
}

/**
 *
 * @param timeValue
 * @returns
 */
export const ageCheck = timeValue => {
	if (timeValue) {
		let date = new Date(timeValue.split(" ")[0])
		let now = new Date()
		const eighteenYears = (567648000 + 432000) * 1000 // 18 Jahre - 5 Tage // ??? schaltjahre oder so
		if (now.getTime() - date.getTime() > eighteenYears) {
			return false
		} else {
			return true
		}
	} else {
		return true
	}
}
/**
 *
 * @param code
 * @returns
 */
export async function checkValidCodes(code) {
	const env = getEnvConfig()
	return await axios
		.get(env.apibase + "getValidCode.php?cv=" + Date.now() + "&code=" + code)
		.then(res => {
			return res
		})
		.catch(error => {
			return error.response.status
		})
}


export enum nameErrorStates {
	NAME_INVALID_CURSE_WORD = 1,
	NAME_INVALID_UNSUPPORTED_CHAR = 2,
	NAME_PENDING = 3,
}
