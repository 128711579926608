import { useAutoAnimate } from "@formkit/auto-animate/react"
import clsx from "clsx"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	onClick?: any
	showLeftCount?: boolean
	hasError?: boolean
	suffix?: string
}

const ElemInput: React.FC<InputProps> = ({ className, props, label, onClick, errMsg, showLeftCount, hasError, suffix }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			<input
				className={clsx(
					props.type === "date" ? "" : " peer ",
					"pt-4 shadow-[inset_0_2px_2px_0_rgba(0,0,0,0.15)] w-full rounded px-4 text-[14px] font-FireSansMedium font-bold h-[60px] leading-[60px] placeholder-transparent outline-0 appearance-none bg-gray ",
					hasError && "js-formerror",
					props.type === "date" && "uppercase",
					props.name === "country" && "bg-white bg-right bg-no-repeat bg-[url('/src/bgimages/inputlocked.png')] bg-[length:auto_90%]"
				)}
				onClick={onClick}
				{...props}
			/>
			<span
				className={clsx(
					"absolute left-0 -top-[40px] font-FireSansRegular text-sm h-[40px] leading-[40px] pointer-events-none",
					props.type === "date" ? "-translate-y-2 " : "peer-focus:-translate-y-4 transition ",
					!!props.value && "text-white ",
					!!props.value ? "text-white" : "text-white",
					hasError && "text-red"
				)}
			>
				{label}
				{props.required && " *"}
			</span>
			{suffix && <div className="absolute top-6 right-3 text-white">{suffix}</div>}
			{showLeftCount && props.maxLength && (
				<div className="absolute top-[20px] right-3 text-black text-[14px]">
					{props.value.length}/{props.maxLength}
				</div>
			)}
			<div ref={animationRef}>{hasError && <div className="formErrorMsg text-[15px] font-bold text-[#ff3f79]">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemInput

// https://play.tailwindcss.com/tKldzVn3U1
