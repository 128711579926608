import { Link } from "react-router-dom"

const Footer = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}
	return (
		<footer className="">
			<div className="grid grid-cols-1 lg:grid-cols-3 items-center justify-items-center align-middle bg-footerBg py-16 px-6 lg:px-44">
				<div className="text-center lg:-translate-y-[10px]">
					<a href="https://www.storck.com/de/" target="_blank" rel="noopener noreferrer" onClick={() => track("Storck")}>
						<img src={process.env.PUBLIC_URL + "/images/knoppers/storck_logo.png"} />
					</a>
				</div>
				<div className="grid grid-cols-2 pt-[36px] lg:pt-0 gap-8">
					<a href="https://www.facebook.com/KnoppersDE/" target="_blank" rel="noopener noreferrer">
						<img className="w-[36px] h-[36px] lg:w-[46px] lg:h-[46px]" src={process.env.PUBLIC_URL + "/images/knoppers/facebook.svg"} onClick={() => track("Facebook")} />
					</a>
					<a href="https://www.instagram.com/knoppers_de/" target="_blank" rel="noopener noreferrer">
						<img className="lg:w-[46px] lg:h-[46px]" src={process.env.PUBLIC_URL + "/images/knoppers/instagram.svg"} onClick={() => track("Instagram")} />
					</a>
				</div>

				<ul className="text-center mt-12 lg:mt-0">
					<li className="lg:inline px-6 pb-6">
						<Link to="/kontakt" className="text-[12px] text-footerColor uppercase  font-FireSansMedium" onClick={() => track("FAQ")}>
							Kontakt
						</Link>
					</li>
					<li className="lg:inline px-6 pb-6">
						<Link
							to="/datenschutz"
							target="_blank"
							rel="noopener noreferrer"
							className="text-[12px] text-footerColor uppercase  font-FireSansMedium"
							onClick={() => track("Datenschutz")}
						>
							Datenschutz
						</Link>
					</li>
					<li className="lg:inline  px-6 pb-2">
						<Link to="/impressum" className="text-[12px] text-footerColor uppercase  font-FireSansMedium" onClick={() => track("Impressum")}>
							Impressum
						</Link>
					</li>
				</ul>
			</div>
			<div className="subfooter  bg-white py-16 px-12 lg:px-24 text-center">
				<img src={process.env.PUBLIC_URL + "/images/subfooter/headline-brandbar.png"} className="mx-auto mb-6" />
				<div className="flex flex-wrap justify-center max-w-[400px] text-center items-center lg:max-w-[1000px] mx-auto gap-8 lg:gap-0">
					<a href="https://www.merci.de/" target="_blank" rel="noopener noreferrer" onClick={() => track("Merci")}>
						<img
							src={process.env.PUBLIC_URL + "/images/subfooter/me-logo-hover.png"}
							className="grayscale mx-auto hover:grayscale-0 opacity-[50%] hover:opacity-[100%] transition-all"
						/>
					</a>
					<a href="https://www.toffifee.de/" target="_blank" rel="noopener noreferrer" onClick={() => track("Toffifee")}>
						<img
							src={process.env.PUBLIC_URL + "/images/subfooter/tof-logo-hover.png"}
							className="grayscale mx-auto hover:grayscale-0 opacity-[50%] hover:opacity-[100%] transition-all"
						/>
					</a>
					<a href="https://www.nimm2.de/" target="_blank" rel="noopener noreferrer" onClick={() => track("Nimm2")}>
						<img
							src={process.env.PUBLIC_URL + "/images/subfooter/n2-logo-hover.png"}
							className="grayscale mx-auto hover:grayscale-0 opacity-[50%] hover:opacity-[100%] transition-all"
						/>
					</a>
					<a href="https://www.werthers-original.de/" target="_blank" rel="noopener noreferrer" onClick={() => track("Werthers")}>
						<img
							src={process.env.PUBLIC_URL + "/images/subfooter/wo-logo-hover.png"}
							className="grayscale mx-auto hover:grayscale-0 opacity-[50%] hover:opacity-[100%] transition-all"
						/>
					</a>
					<a href="https://www.mamba.de/" target="_blank" rel="noopener noreferrer" onClick={() => track("Mamba")}>
						<img
							src={process.env.PUBLIC_URL + "/images/subfooter/ma-logo-hover.png"}
							className="grayscale mx-auto hover:grayscale-0 opacity-[50%] hover:opacity-[100%] transition-all"
						/>
					</a>
					<a href="https://www.dickmanns.de/" target="_blank" rel="noopener noreferrer" onClick={() => track("Dickmanns")}>
						<img
							src={process.env.PUBLIC_URL + "/images/subfooter/dick-logo-hover.png"}
							className="grayscale mx-auto hover:grayscale-0 opacity-[50%] hover:opacity-[100%] transition-all"
						/>
					</a>
					<a href="https://www.riesen.de/" className="place-self-center" target="_blank" rel="noopener noreferrer" onClick={() => track("Riesen")}>
						<img
							src={process.env.PUBLIC_URL + "/images/subfooter/rie-logo-hover.png"}
							className="grayscale mx-auto hover:grayscale-0 opacity-[50%] hover:opacity-[100%] transition-all"
						/>
					</a>
				</div>
			</div>
		</footer>
	)
}

export default Footer
