import { Context } from "../store/store"
import { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { countrys } from "../config"
import CountryBtn from "../components/elements/CountryBtn"
import UpScroll from "../components/Upscroll"

const Country = () => {
	const { globalState, dispatch } = useContext(Context)
	const navigate = useNavigate()

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "Country", action: label })
		} catch (e) {}
	}

	const selectCountry = code => {
		dispatch({
			type: "SET_UPDATE_STATE",
			key: "country",
			value: code,
		})
		navigate("/adresse")
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])
	return (
		<>
			<div className="bg-blue py-6 mb-2 lg:mb-24 blue-brush-border-bottom pt-[50px] pb-32 mt-[90px] relative">
				<h2 className="h2 text-white text-[42px] lg:text-[52px] text-center mb-4 lg:mt-12 font-FireSansCondensedExtraBold">Bestelle Deine Knoppers Tasse</h2>
				<p className="text-[20px] lg:text-[25px] font-FireSansMedium text-white max-w-[75%] mx-auto text-center mb-10 lg:mb-14">
					In welches Land soll Deine Tasse verschickt werden?
				</p>
				<div className="btn-wrapper max-w-[280px] lg:max-w-[500px] mx-auto grid grid-cols-1 lg:grid-cols-2 lg:gap-12">
					{countrys.map((country, i) => {
						return (
							<CountryBtn
								key={i}
								label={country.name}
								country={country.code}
								props={{
									onClick: e => selectCountry(country.code),
								}}
								onClick={() => track(country.name)}
							/>
						)
					})}
				</div>
			</div>
			<div className="mt-24 lg:mt-64 mb-12 lg:mb-24">
				<UpScroll />
			</div>
		</>
	)
}
export default Country
