import axios from "axios"
import { AZPassword, AZReferer, AZUsername, AzProdDomains } from "../../config"

const azBaseDomain = "https://" + (AzProdDomains.indexOf(window.location.host) ? "integ" : "www") + ".az-direct.com/"

export enum AZFIELD {
	ZIP = "zip",
	CITY = "city",
	STREET = "street",
}

// LOCAL STORAGE handling ----------------------------------

// Funktion, um die Session-ID im LocalStorage zu speichern
const saveSessionId = (sessionId: string): void => {
	// Speichern der Session-ID im LocalStorage zusammen mit dem aktuellen Zeitstempel
	const expirationTime = new Date().getTime() + 5 * 60 * 1000 // 5 Minuten in Millisekunden
	const azSessionData = {
		sessionId,
		expirationTime,
	}
	localStorage.setItem("azSessionData", JSON.stringify(azSessionData))
}

// Funktion, um die Session-ID aus dem LocalStorage zu entfernen
const removeAzSessionId = (): void => {
	localStorage.removeItem("azSessionData")
}

// Funktion, um zu überprüfen, ob die Session-ID noch gültig ist
const isSessionIdValid = (): boolean => {
	const azSessionData = localStorage.getItem("azSessionData")
	if (azSessionData) {
		const { sessionId, expirationTime } = JSON.parse(azSessionData)
		const currentTime = new Date().getTime()
		return currentTime < expirationTime && sessionId
	}
	return false
}

export async function getSessionID(): Promise<string> {
	return new Promise(async resolve => {
		let azSession: any = localStorage.getItem("azSessionData")

		if (azSession) {
			// check if existing  session is expired

			const { sessionId, expirationTime } = JSON.parse(azSession)
			const currentTime = new Date().getTime()
			if (currentTime > expirationTime && sessionId) {
				// console.log("AZ Expired")
				await azRegister()
				azSession = localStorage.getItem("azSessionData")
			}
		} else {
			// load new sessionid
			// console.log("load new session id")
			await azRegister()
			azSession = localStorage.getItem("azSessionData")
		}

		resolve(JSON.parse(azSession).sessionId)
	})
}

// AZ Calls ----------------------------------

export async function azRegister() {
	return new Promise(resolve => {
		// console.log("register running")
		axios
			.post(
				azBaseDomain + "eingabeassistent/register",
				{},
				{
					headers: {
						"content-type": "multipart/form-data",
						Accept: "application/json",
						as_referer: AZReferer,
						as_username: AZUsername,
						as_password: AZPassword,
					},
				}
			)
			.then(res => {
				if (res.data.success) {
					saveSessionId(res.data.success.asid)
					// console.log("AZ-Session-ID erhalten und im LocalStorage gespeichert:", res.data.success.asid)

					// Überprüfen, ob die AZ-Session-ID nach 5 Minuten abgelaufen ist
					setTimeout(() => {
						if (!isSessionIdValid()) {
							// console.log("AZ-Session-ID ist abgelaufen!")
							removeAzSessionId()
						}
					}, 5 * 60 * 1000) // 5 Minuten in Millisekunden

					resolve(res.data.success.asid)
				} else {
					// console.log("AZ-Session-ID Fehler:", JSON.stringify(res.data.errors))
					resolve(null)
				}
			})
			.catch(e => {
				resolve(null)
			})
	})
}

export async function azLoadZips(zip, callback, countrycode = "de") {
	const azSessionId: string = await getSessionID()

	if (azSessionId) {
		axios
			.post(
				azBaseDomain + "eingabeassistent/zips?ccode=" + countrycode + "&prefix=" + zip,
				{},
				{
					headers: {
						"content-type": "multipart/form-data",
						Accept: "application/json",
						as_referer: AZReferer,
						as_sessionid: azSessionId,
					},
				}
			)
			.then(res => {
				if (res.data.success) {
					if (res.data.success.zips.length > 0) {
						callback(res.data.success.zips)
					} else {
						callback(null)
					}
				} else {
					callback(null)
					// console.log(res)
				}
			})
			.catch(err => console.log(err))
	} else {
		callback(null)
	}
}

export async function azLoadCities(zip, callback, countrycode = "de") {
	const azSessionId: string = await getSessionID()

	if (azSessionId) {
		axios
			.post(
				azBaseDomain + "eingabeassistent/cities?ccode=" + countrycode + "&zip=" + zip,
				{},
				{
					headers: {
						"content-type": "multipart/form-data",
						Accept: "application/json",
						as_referer: AZReferer,
						as_sessionid: azSessionId,
					},
				}
			)
			.then(res => {
				if (res.data.success) {
					if (res.data.success.cities.length > 0) {
						callback(res.data.success.cities)
					} else {
						callback(null)
					}
				} else {
					callback(null)
					// console.log(res)
				}
			})
			.catch(err => console.log(err))
	} else {
		callback(null)
	}
}

export async function azLoadStreets(zip, street, callback, countrycode = "de") {
	const azSessionId: string = await getSessionID()
	if (azSessionId) {
		axios
			.post(
				azBaseDomain + "eingabeassistent/streets?ccode=" + countrycode + "&zip=" + zip + "&prefix=" + street,
				{},
				{
					headers: {
						"content-type": "multipart/form-data",
						Accept: "application/json",
						as_referer: AZReferer,
						as_sessionid: azSessionId,
					},
				}
			)
			.then(res => {
				if (res.data.success) {
					if (res.data.success.streets.length > 0) {
						callback(res.data.success.streets)
					} else {
						callback(null)
					}
				} else {
					callback(null)
					// console.log(res)
				}
			})
			.catch(err => console.log(err))
	} else {
		callback(null)
	}
}

export function injectIntoForm(key, value) {
	const doc: any = document
	const win: any = window
	const obj: any = Object
	var input = doc.querySelector('input[name="' + key + '"]')
	var nativeInputValueSetter = obj.getOwnPropertyDescriptor(win.HTMLInputElement.prototype, "value").set
	nativeInputValueSetter.call(input, value)
	var ev2 = new Event("input", { bubbles: true })
	input.dispatchEvent(ev2)
}
