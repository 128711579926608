const domains = {
	"www.knoppers-tasse.de": {
		apibase: "/",
		azBaseSubdomain: "www",
	},
	"knoppers-tasse.de": {
		apibase: "/",
		azBaseSubdomain: "www",
	},
	"prod.fe.knoppers-tasse.campaign-loyalty.com": {
		apibase: "/",
		azBaseSubdomain: "www",
	},
	"int.fe.knoppers-tasse.campaign-loyalty.com": {
		apibase: "/inte/",
		azBaseSubdomain: "www",
	},
	// "toffifee-eure-namen.internal.bpghub.de": {
	// 	apibase: "/inte/",
	// },
	localhost: {
		apibase: "/inte/",
	},
	"localhost:3000": {
		apibase: "/inte/",
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "" }
}
