import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import DynamticForm from "../components/form/DynamticForm"
import Popup from "../components/elements/Popup"
import { Context } from "../store/store"
import UpScroll from "../components/Upscroll"
import { formDefinition } from "../components/form/formdefinition"
import { createOrder } from "../components/form/order"
import { useNavigate } from "react-router-dom"
import { scrollToErrorField } from "../components/form/formhelper"

const Address = () => {
	const { globalState } = useContext(Context)
	const [popUpOpen, setPopUpOpen] = useState(false)
	const [popUpOpenName, setPopUpOpenName] = useState(false)
	const navigate = useNavigate()

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "tasse-formular", action: label })
		} catch (e) {}
	}

	const showErrors = (errormessage, errorCodes) => {
		console.log(errorCodes)
	}

	useEffect(() => {
		if (process.env.NODE_ENV !== "development" && globalState.country == "") {
			setTimeout(() => {
				setPopUpOpen(true)
			}, 500)
		}

		if (process.env.NODE_ENV !== "development" && globalState.userName == "") {
			setTimeout(() => {
				setPopUpOpenName(true)
			}, 500)
		}

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})

		// eslint-disable-next-line
	}, [])

	return (
		<>
			<section className="bg-blue py-2 mt-[90px] pt-12 mb-6 lg:mb-24 blue-brush-border-bottom ">
				<div className="max-w-3xl text-center mx-auto">
					<h2 className="h2 text-white text-[42px] lg:text-[52px] text-center mb-4 lg:mt-12 font-FireSansCondensedExtraBold">Bestelle Deine Knoppers Tasse</h2>
					<p className="text-[20px] lg:text-[25px] font-FireSansMedium text-white max-w-[75%] mx-auto text-center mb-10 lg:mb-14"></p>
				</div>

				<DynamticForm
					formDefinition={formDefinition}
					isAzEnabled={true}
					azCountry={globalState.country}
					validationFieldsToSkip={["country"]}
					afterFormFieldsBlock={
						<p className="countryShowOnSelect px-4 font-FireSansRegular my-16 text-[16px] text-white text-center">
							Hier findest du die{" "}
							<Link to="/teilnahmebedingungen" target="_blank" rel="noopener noreferrer" className="underline" onClick={() => track("Teilnahmebedingungen")}>
								Teilnahmebedingungen
							</Link>{" "}
							und Informationen zum{" "}
							<Link to="/datenschutz" target="_blank" rel="noopener noreferrer" className="underline" onClick={() => track("Datenschutz")}>
								Datenschutz
							</Link>
							.
						</p>
					}
					afterFormSubmitBlock={
						<div className="p-8 mt-12">
							<div className="text-white text-left text-[13px] font-Soleil">*Pflichtfelder</div>
						</div>
					}
					validationOnSubmitFailedCallback={fieldName => {
						try {
							const win: any = window
							const dataLayer = win.dataLayer || []
							dataLayer.push({ event: "form-submit", "form-submit": false, "form-name": "contactdetails", "field-error": fieldName })
						} catch (error) {}
					}}
					submitSuccessCallback={inputFields => {
						if (globalState.country == "at") {
							inputFields.country = "at"
						} else {
							inputFields.country = "de"
						}
						inputFields.orderItems = { name: globalState.userName }
						inputFields.sessionID = globalState.sessionID
						createOrder(inputFields, showErrors, scrollToErrorField, navigate)
						track("weiter")
					}}
					popupInstruktions={
						<p className="text-white font-bold font-Soleil text-[15px] ">
							Die Codes befinden sich auf der Innenseite der Aktionspackung. Siehe{" "}
							<Link to="/faq" target="_blank" rel="noopener noreferrer" className="underline">
								FAQ
							</Link>
							.
						</p>
					}
				/>
			</section>
			<div className="mt-24 lg:mt-64 mb-12 lg:mb-24">
				<UpScroll />
			</div>

			{popUpOpen && (
				<Popup
					close={() => {
						setPopUpOpen(false)
						window.location.pathname = "/country"
						return
					}}
					btnStyle="btn-nosize"
					btnLabel="Zurück zur Konfiguration"
				>
					<h3 className="h2 text-[32px] gutter-bottom text-darkblue mb-2">Länderauswahl fehlt</h3>
					<p className="mb-4">Um die Bestellung abzuschließen, muss du im vorherigen Schritt ein Land auswählen</p>
				</Popup>
			)}
			{popUpOpenName && (
				<Popup
					close={() => {
						setPopUpOpenName(false)
						window.location.pathname = "/gestalten"
						return
					}}
					btnStyle="btn-nosize"
					btnLabel="Zurück zur Konfiguration"
				>
					<h3 className="h2 text-[32px] gutter-bottom text-darkblue mb-2">Tassendruck fehlt</h3>
					<p className="mb-4">Bitte gib einen Namen an, der auf die Knoppers Tasse gedruckt werden soll, um die Bestellung abzuschließen</p>
				</Popup>
			)}
		</>
	)
}

export default Address
