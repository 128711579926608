import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import Hero from "../components/Hero"
import { ParallaxProvider } from "react-scroll-parallax"
import Slider from "react-slick"
import UpScroll from "../components/Upscroll"

const Home: React.FC = () => {
	const scrolledRef = React.useRef(false)
	const { hash } = useLocation()
	const hashRef = React.useRef(hash)
	const [arrowVisibilityPrev, setArrowVisbilityPrev] = useState(false)
	const [arrowVisibilityNext, setArrowVisbilityNext] = useState(true)

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "Home", link: label })
		} catch (e) {}
	}

	let settings = {
		dots: false,
		arrows: true,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
		afterChange: current => {
			switch (current) {
				case 0:
					setArrowVisbilityPrev(false)
					break
				case 3:
					setArrowVisbilityNext(false)
					// code block
					break
				default:
					setArrowVisbilityPrev(true)
					setArrowVisbilityNext(true)
			}
		},
	}
	function CustomNextArrow(props) {
		const { className, style, onClick } = props
		return (
			<div
				className={className + (arrowVisibilityNext ? " flex" : " hidden")}
				style={{
					alignItems: "center",
					justifyItems: "center",
					width: "40px",
					height: "40px",
					position: "absolute",
					right: "10px",
					backgroundImage: "none",
					transform: "scaleX(1)",
				}}
				onClick={onClick}
			>
				<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12 2.6C11.3 3.3 11 4.3 11 5.2C11 5.9 11.3 6.9 12 7.8L24.5 20.5L12 33.4C11.4 34 11 35 11 36C11 37 11.3 37.7 12 38.6C13.3 40.2 15.6 40.2 16.9 38.6L31.7 23.1C32.4 22.4 32.7 21.4 32.7 20.5C32.7 19.5 32.4 18.8 31.7 17.9L16.9 2.3C16.3 1.3 15.3 1 14.6 1C13.9 1.3 12.7 1.7 12 2.6Z"
						fill="#2B6BB0"
					/>
				</svg>
			</div>
		)
	}

	function CustomPrevArrow(props) {
		const { className, style, onClick } = props
		return (
			<div
				className={className + (arrowVisibilityPrev ? " flex" : " hidden")}
				style={{
					alignItems: "center",
					justifyItems: "end",
					width: "40px",
					height: "40px",
					position: "absolute",
					left: "10px",
					backgroundImage: "none",
					transform: "scaleX(-1)",
				}}
				onClick={onClick}
			>
				<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12 2.6C11.3 3.3 11 4.3 11 5.2C11 5.9 11.3 6.9 12 7.8L24.5 20.5L12 33.4C11.4 34 11 35 11 36C11 37 11.3 37.7 12 38.6C13.3 40.2 15.6 40.2 16.9 38.6L31.7 23.1C32.4 22.4 32.7 21.4 32.7 20.5C32.7 19.5 32.4 18.8 31.7 17.9L16.9 2.3C16.3 1.3 15.3 1 14.6 1C13.9 1.3 12.7 1.7 12 2.6Z"
						fill="#2B6BB0"
					/>
				</svg>
			</div>
		)
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	})
	return (
		<>
			<ParallaxProvider>
				<Hero />

				<div className="knoppers-gradient py-6 pb-6 lg:mb-12 lg:pb-19 pt-[90px]" id="sogehts">
					<div className="px-4 max-w-[1200px] mx-auto">
						<h2 className="h2 pt-0 lg:pt-6 text-darkblue font-FireSansCondensedExtraBold mb-12 lg:mb-6 text-[38px] lg:text-[50px]">So geht's</h2>
						<div className=" w-full mt-2 lg:mt-12 text-darkblue text-center">
							<Slider {...settings} className="w-full">
								<div>
									<img src={process.env.PUBLIC_URL + "/images/knoppers/soGehts.png"} className="w-[250px] mx-auto lg:w-full " alt="" />
									<div className=" mb-8 lg:mb-0 mt-2 lg:mt-6 max-w-[220px] mx-auto">
										<p className="font-FireSansBold">
											5 Aktionspackungen kaufen und 5 Codes sammeln.
											<br />
											<span className="text-[12px]">
												Teilnehmende Aktionspackungen findest du{" "}
												<a href="#aktionsprodukte" className="underline" onClick={() => track("Aktionspackungen")}>
													hier
												</a>
												.
											</span>
										</p>
									</div>
								</div>
								<div>
									<img src={process.env.PUBLIC_URL + "/images/knoppers/soGehts-1.png"} className="w-[250px] mx-auto lg:w-full" alt="" />
									<div className=" mb-8 lg:mb-0 mt-2 lg:mt-6 max-w-[220px] mx-auto">
										<p className="font-FireSansBold">Tasse mit Wunschnamen personalisieren.</p>
									</div>
								</div>
								<div>
									<img src={process.env.PUBLIC_URL + "/images/knoppers/soGehts-2.png"} className="w-[250px] mx-auto lg:w-full" alt="" />
									<div className=" mb-8 lg:mb-0 mt-2 lg:mt-6 max-w-[220px] mx-auto">
										<p className="font-FireSansBold">5 Aktionscodes von Knoppers Aktionspackungen eingeben.</p>
									</div>
								</div>
								<div>
									<img src={process.env.PUBLIC_URL + "/images/knoppers/soGehts-3.png"} className="w-[250px] mx-auto lg:w-full " alt="" />
									<div className=" mb-8 lg:mb-0 mt-2 lg:mt-6 max-w-[220px] mx-auto">
										<p className="font-FireSansBold">Personalisierte Tasse GRATIS per Post erhalten.</p>
									</div>
								</div>
							</Slider>
						</div>

						<p className="max-w-[85%] text-lightblue text-[18px] font-FireSansMedium lg:font-FireSansBold mx-auto text-center mt-4 lg:mt-24 mb-6">
							Indem du teilnimmst, akzeptierst du unsere{" "}
							<Link to="/teilnahmebedingungen" className="underline" target="_blank" rel="noopener noreferrer" onClick={() => track("Teilnahmebedingungen")}>
								Teilnahmebedingungen
							</Link>
							. Unsere Datenschutzerklärung findest du{" "}
							<Link to="/datenschutz" className="underline" target="_blank" rel="noopener noreferrer" onClick={() => track("Datenschutz")}>
								hier
							</Link>
							.
						</p>
						<div className="text-center">
							<p className="max-w-[85%] text-lightblue text-[20px] lg:text-[25px] font-FireSansMedium mx-auto text-center my-4 lg:mt-12">Teilnahmeschluss: 21.05.2024</p>
							<p className="max-w-[85%] text-lightblue text-[16px] lg:text-[18px] font-FireSansMedium mx-auto text-center my-4 lg:mt-12">
								Teilnahme nur ab 18 Jahren in Deutschland und Österreich möglich.
							</p>
						</div>
					</div>
					<div className="grid justify-items-center mt-24 lg:mt-44 z-[20] relative lg:-translate-y-[60px]" id="aktionsprodukte">
						<div className="btn-wrapper absolute  z-40">
							<Link to="/gestalten" className="btn -mt-[20px] mx-auto" onClick={() => track("JetztGestalten")}>
								Jetzt gestalten!
							</Link>
						</div>
					</div>
				</div>

				<div className="bg-blue py-6 mt-6 blue-brush-border-top pt-[20px] pb-32">
					<div className="px-4 max-w-[1200px] mx-auto">
						<h2 className="h2 uppercase pt-6 font-FireSansCondensedExtraBold mb-12 text-[38px] lg:text-[50px]">Teilnehmende Aktionsprodukte</h2>
						<div className="grid items-center mb-12 lg:mb-24 ">
							<a href="https://www.storck.shop/Knoppers/" target="_blank" className="btn-light" onClick={() => track("zumShop")}>
								<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block mr-6">
									<path
										d="M7 14.1C8.1 14.1 8.9 14.9 8.9 16C8.9 17.1 8.1 17.9 7 17.9C5.9 17.9 5.1 17.1 5.1 16C5.1 15 5.9 14.1 7 14.1ZM15.3 14.2C16.4 14.2 17.2 15 17.2 16.1C17.2 17.2 16.4 18 15.3 18C14.2 18 13.4 17.2 13.4 16.1C13.4 15 14.1 14.2 15.3 14.2ZM3.4 11.7C3.2 11.1 3.4 10.4 3.8 9.8C4 9.6 4 9.5 4.4 9.2L2.4 1.9H0.4L0 0H4L4.6 2.7H17.9L17.4 8.5H17.3L6.4 10.4L6.2 10.5C5.7 10.7 5.6 10.7 5.5 11C5.4 11.2 5.3 11.2 5.4 11.5C5.5 11.9 5.9 12.2 6.2 12.1H17.4V14H6.3C4.7 14.1 3.7 13 3.4 11.7ZM6.3 14C6.2 14 6.2 14 6.3 14V14Z"
										fill="white"
									/>
								</svg>
								zum Shop
								<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block ml-2">
									<g clipPath="url(#clip0_172_1202)">
										<path
											d="M4.04286 1.18095C3.85953 1.36428 3.78096 1.62619 3.78096 1.8619C3.78096 2.04524 3.85953 2.30714 4.04286 2.54285L7.31667 5.86904L4.04286 9.24762C3.88572 9.40476 3.78096 9.66666 3.78096 9.92857C3.78096 10.1905 3.85953 10.3738 4.04286 10.6095C4.38334 11.0286 4.98572 11.0286 5.3262 10.6095L9.20239 6.55C9.38572 6.36666 9.46429 6.10476 9.46429 5.86904C9.46429 5.60714 9.38572 5.42381 9.20239 5.18809L5.3262 1.10238C5.16906 0.840473 4.90715 0.761902 4.72382 0.761902C4.54048 0.840473 4.2262 0.945235 4.04286 1.18095Z"
											fill="white"
										/>
									</g>
									<defs>
										<clipPath id="clip0_172_1202">
											<rect width="11" height="11" fill="white" transform="translate(0.899994 0.5)" />
										</clipPath>
									</defs>
								</svg>
							</a>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full font-NutellaFont mt-12 lg:mt-12 text-white text-center text-[18px]">
							<div>
								<img src={process.env.PUBLIC_URL + "/images/knoppers/aktionsprodukte.png"} className="w-full max-w-[240px] mx-auto lg:mt-0" alt="" />
								<div className=" mb-8 lg:mb-0 mt-2 lg:mt-6">
									<p className="font-FireSansBold">Knoppers Classic</p>
									<p className="font-FireSansRegular">8er, 200 g</p>
								</div>
							</div>
							<div>
								<img src={process.env.PUBLIC_URL + "/images/knoppers/aktionsprodukte-1.png"} className="w-full max-w-[240px] mx-auto mb-4" alt="" />
								<div className=" mb-8 lg:mb-0 mt-2 lg:mt-6">
									<p className="font-FireSansBold">Knoppers Erdnuss</p>
									<p className="font-FireSansRegular">8er, 200 g</p>
								</div>
							</div>
							<div>
								<img src={process.env.PUBLIC_URL + "/images/knoppers/aktionsprodukte-2.png"} className="w-full max-w-[240px] mx-auto mb-4" alt="" />
								<div className=" mb-8 lg:mb-0 mt-2 lg:mt-6">
									<p className="font-FireSansBold">Knoppers Joghurt</p>
									<p className="font-FireSansRegular">8er, 200 g</p>
								</div>
							</div>
							<div>
								<img src={process.env.PUBLIC_URL + "/images/knoppers/aktionsprodukte-3.png"} className="w-full max-w-[240px] mx-auto mb-4" alt="" />
								<div className=" mb-8 lg:mb-0 mt-2 lg:mt-6">
									<p className="font-FireSansBold">Knoppers Black & White</p>
									<p className="font-FireSansRegular">8er, 200 g</p>
								</div>
							</div>
						</div>
						<img className="mt-6 hidden md:block" src={process.env.PUBLIC_URL + "/images/knoppers/stoerer-aktionsprodukte-desktop.png"} />
						<img className="mt-6 ml-[20px] max-w-[95%] block md:hidden" src={process.env.PUBLIC_URL + "/images/knoppers/stoerer-aktionsprodukte-mobile.png"} />

						<div className="btn-wrapper grid items-center mt-20">
							<Link to="/gestalten" className="btn-white " onClick={() => track("JetztGestalten")}>
								Jetzt gestalten!
							</Link>
						</div>
					</div>
				</div>
				<div className="mt-24 lg:mt-24 mb-12 lg:mb-24">
					<UpScroll />
				</div>
			</ParallaxProvider>
		</>
	)
}
export default Home
