import { Link } from "react-router-dom"
import { STEP } from "../../config"
import { SVGArrowLeft, SVGCheck } from "../elements/SVG"
import Composition from "./Composition"

const Overview = ({ setCurrStep }) => {
	return (
		<>
			<h2 className="h2 lg:mb-6">Fast fertig! Sieht super aus!</h2>
			<Composition />

			<div className="max-w-md mx-auto">
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center py-4">
					<button
						className="btn"
						onClick={() => {
							setCurrStep(STEP.CITYNAME)
							try {
								// eslint-disable-next-line
								let dataLayer = window["dataLayer"] || []
								dataLayer.push({
									event: "customClick",
									clickName: "zurück",
								})
							} catch (e) {}
						}}
					>
						<span>Zurück</span>
						<SVGArrowLeft />
					</button>

					<Link to="/adresse" className="btn">
						<span>Etikett bestellen</span>
						<SVGCheck />
					</Link>
				</div>
				<div className="text-center">Bitte prüfe dein Etikett genau. Gleich sind keine Änderungen mehr möglich.</div>
			</div>
		</>
	)
}
export default Overview
