const UpScroll = () => {
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}
	return (
		<div className="grid grid-cols-1 justify-items-center">
			<button
				onClick={scrollToTop}
				className="bg-blue rounded-[50%] w-[40px] h-[40px] lg:w-[46px] lg:h-[46px] grid justify-items-center items-center hover:bg-darkblue transition-allhover:transition-all cursor-pointer"
			>
				<svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block -rotate-[90deg]">
					<g clipPath="url(#clip0_172_1202)">
						<path
							d="M4.04286 1.18095C3.85953 1.36428 3.78096 1.62619 3.78096 1.8619C3.78096 2.04524 3.85953 2.30714 4.04286 2.54285L7.31667 5.86904L4.04286 9.24762C3.88572 9.40476 3.78096 9.66666 3.78096 9.92857C3.78096 10.1905 3.85953 10.3738 4.04286 10.6095C4.38334 11.0286 4.98572 11.0286 5.3262 10.6095L9.20239 6.55C9.38572 6.36666 9.46429 6.10476 9.46429 5.86904C9.46429 5.60714 9.38572 5.42381 9.20239 5.18809L5.3262 1.10238C5.16906 0.840473 4.90715 0.761902 4.72382 0.761902C4.54048 0.840473 4.2262 0.945235 4.04286 1.18095Z"
							fill="white"
						/>
					</g>
					<defs>
						<clipPath id="clip0_172_1202">
							<rect width="11" height="11" fill="white" transform="translate(0.899994 0.5)" />
						</clipPath>
					</defs>
				</svg>
			</button>
			<p className="text-[14px] text-footerColor uppercase  font-FireSansMedium mt-2">Seitenanfang</p>
		</div>
	)
}
export default UpScroll
