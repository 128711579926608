import axios from 'axios';
import { getEnvConfig } from '../../env';
import { useNavigate } from 'react-router-dom';

const backendValidationMapping = {
  "code1": "isInvalid_code1",
  "code2": "isInvalid_code2",
  "code3": "isInvalid_code3",
  "code4": "isInvalid_code4",
  "code5": "isInvalid_code5",
  "email": "isInvalid_email",
  "firstName": "isInvalid_firstname",
  "lastName": "isInvalid_lastname",
  "captcha": "isInvalid_captcha",
  "addressStreet": "isInvalid_street",
  "addressCity": "isInvalid_city",
  "addressPostcode": "isInvalid_zip",
  // "birthdate" : "isInvalid_dateofbirth",
};

const env = getEnvConfig()

function replaceKeysInObject(obj, oldKey, newKey) {
  const newObj = {};

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const newObject = {};

      for (const prop in value) {
        if (Object.hasOwnProperty.call(value, prop)) {
          const newProp = prop === oldKey ? newKey : prop;
          newObject[newProp] = value[prop];
        }
      }

      newObj[key] = newObject;
    }
  }

  return newObj;
}

function removeKeyFromObject(obj, keyToRemove) {
  const newObj = {};

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const newObject = {};

      for (const prop in value) {
        if (Object.hasOwnProperty.call(value, prop) && prop !== keyToRemove) {
          newObject[prop] = value[prop];
        }
      }

      newObj[key] = newObject;
    }
  }

  return newObj;
}



export const createOrder = (inputFields, updateFormState?, scrollToErrorField?, navigate?) => {

  const data = {
    address: inputFields,
    orderItems: inputFields.orderItems,
    locale: "de-DE",
  };
  axios.post(env.apibase + 'order.php?cv=' + Date.now(), data)
    .then(res => {
      if (res.data.externalIdentifier) {
        try {
          const win: any = window
          const dataLayer = win.dataLayer || []
          dataLayer.push({ 'event': 'form-submit', 'form-submit': true, 'form-name': 'contactdetails' });
        } catch (error) { }

        navigate("/danke/")

      } else {
        if (res.data.errors.children.captcha.errors[0] === "Der eingegebene Captcha wurde nicht korrekt geladen.") {
          parseOrderErrors(res.data.errors, updateFormState, scrollToErrorField);
        } else if (res.data.errors.children.captcha.errors[0] === "Der eingegebene Captcha ist ungültig.") {
          parseOrderErrors(res.data.errors, updateFormState, scrollToErrorField);
        } else if (res.data.errors.errors[0] === "Too many attempts. You have been blocked for a few minutes.") {
          parseOrderErrors(res.data.errors, updateFormState, scrollToErrorField);
        }
      }
    })
    .catch((error) => {
      if (error.response && (error.response.status >= 400 && error.response.status < 500)) { // for 40X cases
        parseOrderErrors(error.response.data.errors, updateFormState, scrollToErrorField);
      } else {
        updateFormState("UPLOADINCOMPLETE");
      }
    })
}


/**
 *
 * @param errors
 * @param updateFormState
 * @param scrollToErrorField
 */
const parseOrderErrors = (errors, updateFormState, scrollToErrorField) => {
  if (errors) {

    let tmp_errorCollection = {};
    for (const key in errors.children) {
      if (errors.children[key].errors && backendValidationMapping[key]) {
        console.log(tmp_errorCollection[backendValidationMapping[key]])
        tmp_errorCollection[backendValidationMapping[key] + "_errMsg"] = errors.children[key].errors.join(" ");
        tmp_errorCollection[backendValidationMapping[key]] = true;
      }
    }
    updateFormState("PARSE_ORDER_ERRORS", tmp_errorCollection);
    scrollToErrorField();
  }
}
