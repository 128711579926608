interface PopupProps {
	close: any
	btnStyle?: any
	btnLabel?: any
}
const Popup: React.FC<PopupProps> = ({ close, btnStyle, btnLabel, children }) => {
	return (
		<section className="transition duration-150 ease-in-out fixed top-0 left-0 w-full h-full z-20">
			<div className="w-96 absolute left-1/2 top-1/2 bg-black/60  -translate-x-1/2 -translate-y-1/2 p-8 text-center rounded-2xl">
				{children}
				<button className={btnStyle || "btn"} onClick={close}>
					{btnLabel || "Okay"}
				</button>
			</div>
		</section>
	)
}

export default Popup
