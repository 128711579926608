import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"

const initialState = {
	compositions: {
		// "0": {
		// 	schuberName: "Df",
		// 	schuberStatus: "ALLOWED",
		// },
		// "1": {
		// 	schuberName: "Sdf",
		// 	schuberStatus: "ALLOWED",
		// },
		// "2": {
		// 	schuberName: "Df",
		// 	schuberStatus: "ALLOWED",
		// },
		// "3": {
		// 	schuberName: "Df",
		// 	schuberStatus: "ALLOWED",
		// },
	},
	code1: "",
	code2: "",
	code3: "",
	code4: "",
	code5: "",
	country: "Deutschland",
	captchaIdent: "",
	userName: "",
	sessionID: null,
}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store
