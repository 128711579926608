import { useAutoAnimate } from "@formkit/auto-animate/react"
import clsx from "clsx"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	onClick?: any
	showLeftCount?: boolean
	hasError?: boolean
	suffix?: string
	isCodeValid?: boolean
	isCodeInvalid?: boolean
}

const ElemInputCodes: React.FC<InputProps> = ({ className, props, label, onClick, errMsg, showLeftCount, hasError, suffix, isCodeValid = false, isCodeInvalid }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "") + (isCodeValid == true ? "valid" : "") + (isCodeInvalid == true ? "invalid" : "")}>
			<input
				className={clsx(
					props.type === "date" ? "" : " peer ",
					"shadow-[inset_0_2px_2px_0_rgba(0,0,0,0.15)] w-full rounded-[25px] font-FireSansRegular px-4 text-[14px] text-black font-bold uppercase h-[40px] leading-[40px]  outline-0 appearance-none bg-gray text-center",
					hasError && "js-formerror"
				)}
				data-name={props.name}
				onClick={onClick}
				{...props}
				disabled={isCodeValid}
			/>
			<span
				className={clsx(
					"absolute left-0 -top-[40px] font-FireSansRegular text-sm h-[40px] leading-[40px] uppercase pointer-events-none",
					props.type === "date" ? "-translate-y-2 " : "peer-focus:-translate-y-4 transition ",
					!!props.value && "text-white -translate-y-4",
					!!props.value ? "text-white" : "text-white",
					hasError && "text-red"
				)}
			></span>
			{suffix && <div className="absolute top-6 right-3 text-white">{suffix}</div>}
			{showLeftCount && props.maxLength && (
				<div className="absolute top-[20px] right-3 text-black text-[14px]">
					{props.value.length}/{props.maxLength}
				</div>
			)}
			<div ref={animationRef}>{hasError && <div className="formErrorMsg  text-[15px] font-bold text-[#ff3f79]">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemInputCodes

// https://play.tailwindcss.com/tKldzVn3U1
