import { Link, useLocation } from "react-router-dom"
import { useState, useEffect } from "react"
import { navigation, subnavigation } from "../config"
import MobileMenu from "./MobileMenu"

const Header = ({}) => {
	const [showMenu, setShowMenu] = useState(false)
	const [activePage, setActivePage] = useState(window.location.pathname)
	const location = useLocation()
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "Navigation", link: label })
		} catch (e) {}
	}
	useEffect(() => {
		setActivePage(location.pathname)
	}, [location])
	return (
		<>
			<div>{showMenu && <MobileMenu setShowMenu={setShowMenu} isPromoOver={false} scrollToAnchor={false} />}</div>
			<header className="bg-gray items-center align-items-center shadow-[-1px_0_8px_0_rgba(0,0,0,.2)] fixed w-full z-[400] top-0">
				<div className="grid grid-cols-[110px_1fr] lg:grid-cols-[35%_65%] xl:grid-cols-[40%_60%] h-[60px] lg:h-[100px] transition-all ">
					<Link
						to="/"
						className="bg-[url('/src/bgimages/knoppers/logo-mobile.png')] lg:bg-[url('/src/bgimages/knoppers/logo-desktop-2x.png')] bg-contain bg-no-repeat lg:bg-contain h-[60px] lg:h-[100px] w-full"
						onClick={() => track("Knoppers")}
					></Link>
					<div className="items-center justify-left hidden lg:grid">
						<ul className="w-full outline-none focus:outline-none">
							{navigation.map((item, index) => {
								return (
									<li key={index} className="inline-block outline-none focus:outline-none  outline-offset-0">
										<Link
											className={
												"" + activePage === item.navIdent
													? "text-darkblue text-[17px] hover:text-lightblue uppercase font-FireSansBold px-4 transition-all outline-[0] focus:outline-[0] outline-offset-0 "
													: "text-lightblue text-[17px] hover:text-darkblue uppercase font-FireSansBold px-4 transition-all outline-[0] focus:outline-[0] outline-offset-0 "
											}
											to={item.url}
											onClick={() => track(item.title)}
										>
											{item.title}
										</Link>
									</li>
								)
							})}
						</ul>
					</div>
					<div className="items-center justify-right grid justify-items-end lg:hidden text-right lg:pr-2" onClick={() => setShowMenu(true)}>
						<div className="nav-btn-wrapper h-[60px] w-[80px] p-6 pt-[30px] cursor-pointer">
							<p className="h-[3px] bg-darkblue rounded-[5px] w-[30px] select-none"></p>
							<p className="h-[3px] bg-darkblue rounded-[5px] w-[30px] -mt-[9px] select-none"></p>
							<p className="h-[3px] bg-darkblue rounded-[5px] w-[30px] mt-[9px] select-none"></p>
						</div>
					</div>
				</div>
			</header>
			<div className="mt-[70px] lg:mt-[100px]"></div>
		</>
	)
}

export default Header
