const Reducer = (state, action) => {
	switch (action.type) {
		case "SET_UPDATE_STATE":
			return {
				...state,
				[action.key]: action.value,
			}
		case "SET_UPDATE_CODES":
			return {
				...state,
				[action.key]: action.value,
			}
		case "SET_FORM":
			return {
				...state,
				form: action.form,
			}
		default:
			return state
	}
}

export default Reducer
