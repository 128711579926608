import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { updateIframeHeight, updateIframeScrollTo } from "../helper"

const Imprint: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		updateIframeScrollTo(0)
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})

		updateIframeHeight()
		// eslint-disable-next-line
	}, [])

	return (
		<div>
			<>
				<div className="container max-w-[400px] mx-auto px-4 text-center">
					<h2 className="h2 text-darkblue  mt-12 lg:mt-24">Impressum</h2>
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-darkblue leading-tight mb-2">August Storck KG</h2>
					<p className="text-darkblue mb-4">
						Waldstraße 27
						<br />
						13403 Berlin
						<br />
						Deutschland
					</p>
					<p className="text-darkblue mb-4">
						Telefon: +49 30 41773-03
						<br />
						Fax: +49 30 41773 12-7000
						<br />
						E-Mail: <a href="mailto:info@de.storck.com">info@de.storck.com</a>
					</p>
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-darkblue leading-tight mb-2">Registerangaben:</h2>
					<p className="text-darkblue mb-4">
						Registergericht: Amtsgericht Charlottenburg
						<br />
						Handelsregisternummer: HRA 22321
						<br />
						U-St-Id-Nr.: DE 136654941
					</p>
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-darkblue leading-tight mb-2">Vertretungs­berechtigt nach TmG:</h2>
					<p className="text-darkblue mb-4">
						Axel Oberwelland
						<br />
						August Storck Verwaltungsgesellschaft mbH
						<br />
						Geschäftsführer:
						<br />
						Sascha Gervers
						<br />
						Jan Hendricks
						<br />
						Achim Westerhoff
						<br />
						Michael Zink
					</p>
					<p className="text-darkblue mb-4">
						Registergericht: Amtsgericht Charlottenburg
						<br />
						Handelsregisternummer: HRB 30978
					</p>
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-darkblue leading-tight mb-2">Copyright:</h2>
					<p className="text-darkblue mb-4">
						© Copyright 2024, AUGUST STORCK KG, Deutschland Alle Rechte vorbehalten. Die auf unserer Webseite abrufbaren Inhalte unterliegen dem Schutz des Urheberrechts und
						anderer Schutzgesetze. Dies gilt insbesondere für Texte, Bilder, Grafiken, Audio- und Videodateien sowie deren Anordnung auf der Seite. Soweit nicht anders angegeben,
						sind alle Markenzeichen auf Storck Websites markenrechtlich geschützt.
					</p>
					<p className="text-darkblue mb-4">Jegliche Nutzung bedarf der vorherigen schriftlichen Zustimmung durch die AUGUST STORCK KG.</p>
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-darkblue leading-tight mb-2">Website Realisierung:</h2>
					<p className="text-darkblue mb-4">direct services Gütersloh GmbH </p>
				</div>
			</>
		</div>
	)
}
export default Imprint
