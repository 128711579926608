import { useEffect, useState, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import Alert from "../Alert"
import Spinner from "../Spinner"
import { AZFIELD, azLoadCities, azLoadStreets, azLoadZips, injectIntoForm } from "./AZ"
import PrefillForm from "./PrefillForm"
import SimpleCaptcha from "./SimpleCaptcha"
import ElemCheckbox from "./fields/Checkbox"
import ElemInput from "./fields/Input"
import Popup from "./fields/Popup"
import ElemRadio from "./fields/Radio"
import { Context } from "../../store/store"
import ElemSelect from "./fields/Select"
import ElemInputCodes from "./fields/InputCodes"
import { checkValidCodes } from "./formhelper"
import { MaxCodeLetters, countryTranslation } from "../../config"

import { GLOBAL_ERROR_MSG, INPUT_TYPES, formDefinition } from "./formdefinition"
import { globalAgent } from "http"

declare const window: any
declare const document: any

type DynamicFormProps = {
	formDefinition: any
	azCountry?: string
	afterFormFieldsBlock?: any
	afterFormSubmitBlock?: any
	popupInstruktions?: any
	isAzEnabled: boolean
	validationFieldsToSkip?: any // input name array ["googleAutocomplete"]
	validationOnSubmitFailedCallback?: Function
	submitSuccessCallback: Function
}

const DynamticForm: React.FC<DynamicFormProps> = ({
	formDefinition,
	azCountry,
	afterFormFieldsBlock,
	isAzEnabled,
	afterFormSubmitBlock,
	popupInstruktions,
	validationFieldsToSkip = [],
	validationOnSubmitFailedCallback = () => {},
	submitSuccessCallback = () => {},
}) => {
	const [formState, setFormState] = useState<any>(null)
	const { globalState, dispatch } = useContext(Context)
	interface Validation {
		showValidation: boolean
	}
	const [validation, setValidation] = useState<Validation>({
		showValidation: false,
	})
	const [isLoading, setIsLoading] = useState(false)
	const [showValidation, setShowValidation] = useState<boolean>(false)
	const [captchaIdent, setCaptchaIdent] = useState("")
	const [errorMsg, setErrorMsg] = useState<string>("")
	const [codeInstructionDialogOpen, setCodeInstructionDialogOpen] = useState(false)
	const [useCodes, setUseCodes] = useState<Array<string>>([])

	const navigate = useNavigate()

	// --------------- AZ stuff

	const [azZips, setAzZips] = useState<any>(null)
	const [azCities, setAzCities] = useState<any>(null)
	const [azStreets, setAzStreets] = useState<any>(null)

	function getAzList(list: string[], key: string) {
		let coll: any = []
		for (let i = 0; i < list.length; i++) {
			coll.push(
				<li
					className="whitespace-nowrap inline-block rounded-[24px] border-white/50 border-2 px-2 m-1"
					onClick={() => {
						if (key === AZFIELD.ZIP) {
							handleZipInjection(list[i])
						} else if (key === AZFIELD.CITY) {
							handleCityInjection(list[i])
						} else if (key === AZFIELD.STREET) {
							handleStreetInjection(list[i])
						}
					}}
				>
					{list[i]}
				</li>
			)
		}
		return coll
	}
	function handleZipInjection(value: string) {
		injectIntoForm(AZFIELD.ZIP, value)
		setAzZips(null)
		azLoadCities(
			value,
			res => {
				setAzCities(res)
			},
			azCountry
		)
	}
	function handleCityInjection(value: string) {
		injectIntoForm(AZFIELD.CITY, value)
		setAzCities(null)
	}
	function handleStreetInjection(value: string) {
		injectIntoForm(AZFIELD.STREET, value)
		setTimeout(() => setAzStreets(null), 700) //creates loop without timeout
	}

	// ------------------- AZ END

	useEffect(() => {
		// set validation states automatically, based on form defintion
		const formKeys = Object.keys(formDefinition)
		for (let i = 0; i < formKeys.length; i++) {
			if (formDefinition[formKeys[i]].inputProps) {
				formDefinition[formKeys[i]].inputProps.value = formDefinition[formKeys[i]].inputProps.defaultValue
				formDefinition[formKeys[i]].isInvalid = formDefinition[formKeys[i]].inputProps.defaultValue ? false : true
			}
		}
		resetCodes()
		setFormState(formDefinition)

		// eslint-disable-next-line
	}, [])

	/**
	 *
	 * @param e
	 * @param key
	 */
	const formChanged = (e, key, errorMessage?) => {
		// special handling for checkboxes
		if (key === "lastname" || key === "firstname" || key === "city" || key === "street") e.target.value = e.target.value.replace(/[0-9]/g, "")
		if (e.target.type === "checkbox") {
			e.target.value = e.target.checked
			if (e.target.value === "false") {
				e.target.value = ""
			}
		}
		if (e.target.getAttribute("data-name") && e.target.getAttribute("data-name").includes("code")) {
			checkCodes(e)
		}

		let isFieldInvalid = true
		const element = document.querySelector('input[name="' + key + '"]')
		if (element.validity.valid) {
			isFieldInvalid = false
		}

		if (key === "dateofbirth") {
			isFieldInvalid = ageCheck(e.target.value) //TODO:
		}

		// az stuff
		if (isAzEnabled) {
			if (key === AZFIELD.ZIP && e.target.value.length > 2 && e.target.value.length < 5) {
				azLoadZips(
					e.target.value,
					res => {
						setAzZips(res)
					},
					azCountry
				)
			}
			if (key === AZFIELD.STREET && e.target.value.length > 2) {
				azLoadStreets(
					formState[AZFIELD.ZIP].inputProps.value,
					e.target.value,
					res => {
						setAzStreets(res)
					},
					azCountry
				)
			}
		}
		// az stuff END

		let tmpFormState = { ...formState }
		tmpFormState[key].isInvalid = isFieldInvalid
		tmpFormState[key].inputProps.value = e.target.value
			.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9 "#&()*+,-.\\/:?@_u|~áçíóôąćČčđėěğīİıĶŁłńŕśŞşŠšūźżŽž̈БКМСавгжиклнорстьіẞ–—‘’']/giu, "")
			.replace("https://www", "")
			.replace("mailto:", "")
		setFormState(tmpFormState)
	}

	const resetCodes = () => {
		for (let i = 0; i <= 5; i++) {
			if (globalState["code" + i] !== undefined || globalState["code" + i] !== null)
				dispatch({
					type: "SET_UPDATE_STATE",
					key: "code" + i,
					value: "",
				})
		}
	}

	async function checkCodes(e) {
		let codeInputIdentifier = e.target.getAttribute("data-name")
		if (e.target.value.length == MaxCodeLetters) {
			let isCodeValid = await checkValidCodes(e.target.value.toUpperCase())
			if (!useCodes.includes(e.target.value)) {
				if (isCodeValid !== 400 && isCodeValid.data.valid) {
					setUseCodes(useCodes => [...useCodes, e.target.value])
					dispatch({
						type: "SET_UPDATE_STATE",
						key: codeInputIdentifier,
						value: isCodeValid.data.code,
					})
				} else {
					setIsLoading(false)
					dispatch({
						type: "SET_UPDATE_STATE",
						key: codeInputIdentifier,
						value: "invalid",
					})
				}
			} else {
				setIsLoading(false)
				dispatch({
					type: "SET_UPDATE_STATE",
					key: codeInputIdentifier,
					value: "invalid",
				})
				trackCodes("Der eingegebene Code ist nicht korrekt.")
			}
		}
	}

	const scrollToErrorField = () => {
		let selector: string | null = null
		const formKeys = Object.keys(formState)
		for (let i = 0; i < formKeys.length; i++) {
			if (formState[formKeys[i]].isInvalid) {
				selector = formKeys[i]
				break
			}
		}

		let errField = document.querySelector('input[name="' + selector + '"]')
		if (errField) {
			window.scrollTo({
				top: window.pageYOffset + errField.getBoundingClientRect().top - 200,
				left: 0,
				behavior: "smooth",
			})
		}
	}

	const ageCheck = timeValue => {
		if (timeValue) {
			let date = new Date(timeValue.split(" ")[0])
			let now = new Date()

			const eighteenYears = (567648000 + 432000) * 1000 // 18 Jahre - 5 Tage ??? schaltjahre oder so

			if (now.getTime() - date.getTime() > eighteenYears) {
				return false
			} else {
				return true
			}
		} else {
			return true
		}
	}

	/**
	 *
	 * @param e
	 */
	const formSubmit = e => {
		e.preventDefault()
		setShowValidation(true)
		setIsLoading(true)
		if (areAllFieldsValid()) {
			submitSuccessCallback(returnFormValues())
			setIsLoading(false)
		} else {
			setIsLoading(false)
			scrollToErrorField()
		}
	}

	function trackCodes(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "tasse-aktionscode", link: "fehler - " + label })
		} catch (e) {}
	}

	function trackForm(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "tasse-formular", link: "fehler - " + label })
		} catch (e) {}
	}

	const areAllFieldsValid = () => {
		let allFieldsValid = true
		let fieldsToSkip = validationFieldsToSkip
		const formKeys = Object.keys(formState)

		for (let i = 0; i < formKeys.length; i++) {
			const formItem = formState[formKeys[i]]
			if (fieldsToSkip.indexOf(formKeys[i]) < 0) {
				if (formItem.isInvalid) {
					allFieldsValid = false
					validationOnSubmitFailedCallback()
					break
				}
			}
		}
		return allFieldsValid
	}
	const returnFormValues = () => {
		let collection = {}
		let fieldsToSkip = validationFieldsToSkip
		collection["captchaIdent"] = captchaIdent
		Object.keys(formState).map((item, i) => {
			if (formState[item].name) {
				if (fieldsToSkip.indexOf(formState[item].name) < 0) {
					collection[formState[item].name] = formState[item].inputProps.value
				}
			}
		})
		return collection
	}

	/**
	 *
	 * @param item
	 * @param index
	 * @returns
	 */
	const getField = itemKey => {
		const item = formState[itemKey]

		if (item.type === INPUT_TYPES.COMPONENT) {
			if (item.component === "CODEINSTRUCTIONS") {
				return (
					<div key={"ferrFormItem" + itemKey} className="col-span-2">
						<h3 className="h3 text-white pb-3 text-center text-[25px]">Deine Aktionscodes</h3>
					</div>
				)
			} else {
				return <div key={"ferrFormItem" + itemKey}>{item.component}</div>
			}
		} else if (item.type === INPUT_TYPES.CHECKBOX) {
			return (
				<ElemCheckbox
					label={item.label}
					props={{
						...item.inputProps,
						name: itemKey,
						value: formState.value,
						defaultValue: formState.defaultValue,
						onClick: e => formChanged(e, itemKey, item.errorMessage),
					}}
					hasError={showValidation && item.isInvalid}
					errMsg={item.errorMessage ? item.errorMessage : null}
				/>
			)
		} else if (item.type === INPUT_TYPES.SELECT) {
			return (
				<ElemSelect
					key={"frrForm" + itemKey}
					label={item.label}
					props={{
						name: itemKey,
						type: "select",
						onChange: e => formChanged(e, itemKey, item.errorMessage),
						defaultValue: formState.defaultValue,
						...item.inputProps,
					}}
					hasError={showValidation && item.isInvalid}
					errMsg={item.errorMessage ? item.errorMessage : null}
				>
					{item.options.map(option => (
						<option value={option.value} key={itemKey + option.value}>
							{option.label}
						</option>
					))}
				</ElemSelect>
			)
		} else if (item.type === INPUT_TYPES.RADIO) {
			return (
				<div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-start align-top">
					{item.options.map((option, optIndex) => {
						return (
							<ElemRadio
								key={"frrFormRadio" + optIndex}
								label={option.label}
								props={{
									name: itemKey,
									value: option.value,
									type: "radio",
									onChange: e => formChanged(e, itemKey, item.errorMessage),
									...item.inputProps,
								}}
							></ElemRadio>
						)
					})}
				</div>
			)
		} else if (item.type === INPUT_TYPES.CODES) {
			return (
				<>
					<ElemInputCodes
						label={item.label}
						props={{
							...item.inputProps,
							name: itemKey,
							value: formState.value,
							defaultValue: formState.defaultValue,
							onChange: e => formChanged(e, itemKey, item.errorMessage),
						}}
						isCodeValid={globalState[itemKey] !== "" && globalState[itemKey] !== "invalid" ? true : false}
						isCodeInvalid={globalState[itemKey] == "invalid" ? true : false}
						hasError={(showValidation && item.isInvalid) || globalState[itemKey] == "invalid"}
						errMsg={item.errorMessage ? item.errorMessage : null}
					/>
					{itemKey === "code5" && <div className="mb-12"> </div>}
				</>
			)
		} else {
			return (
				<>
					{itemKey === "captcha" ? (
						<div className="justify-items-center">
							<SimpleCaptcha
								key={"frrForm1" + itemKey}
								formElementChanged={formChanged}
								validation={validation}
								captchaInputValue={formState.value}
								setCaptchaIdent={e => setCaptchaIdent(e)}
							/>{" "}
						</div>
					) : (
						<>
							{itemKey === "country" ? (
								<>
									<ElemInput
										key={"frrForm" + itemKey}
										label={item.label}
										props={{
											name: itemKey,
											type: item.type,
											value: globalState.country == "de" ? "Deutschland" : "Österreich",
											defaultValue: globalState.country == "de" ? "Deutschland" : "Österreich",
											disabled: true,
											...item.inputProps,
											className:
												"shadow-[inset_0_2px_2px_0_rgba(0,0,0,0.15)] w-full rounded-[24px] px-4 text-sm text-lightblue h-[40px] leading-[40px] placeholder-transparent outline-0 appearance-none bg-gray pt-0 mt-0",
										}}
										hasError={showValidation && globalState.country == ""}
										errMsg={item.errorMessage ? item["isInvalid_" + itemKey + "_errMsg"] || item.errorMessage : null} //TODO:
									/>
								</>
							) : (
								<ElemInput
									key={"frrForm" + itemKey}
									label={itemKey == "housenr" ? (globalState.country == "de" ? "Hausnummer" : "Hausnummer/Stiege/Türnummer") : item.label}
									props={{
										name: itemKey,
										type: item.type,
										onChange: e => formChanged(e, itemKey, item.errorMessage),
										value: formState.value,
										defaultValue: formState.defaultValue,
										maxLength: itemKey == "zip" ? (globalState.country == "de" ? 5 : 4) : item.inputProps.maxLength,
										...item.inputProps,
										className:
											"shadow-[inset_0_2px_2px_0_rgba(0,0,0,0.15)] w-full rounded-[24px] px-4 text-sm text-black h-[40px] leading-[40px] placeholder-transparent outline-0 appearance-none bg-gray pt-0 mt-0",
									}}
									hasError={showValidation && item.isInvalid}
									errMsg={item.errorMessage ? item["isInvalid_" + itemKey + "_errMsg"] || item.errorMessage : null}
								/>
							)}

							{isAzEnabled && (
								<>
									{itemKey === AZFIELD.ZIP && azZips && (
										<div className="max-h-[99px] mt-2 overflow-y-auto text-white font-FireSansRegular text-[14px]">
											Vorschläge: <ul>{getAzList(azZips, AZFIELD.ZIP)}</ul>
										</div>
									)}
									{itemKey === AZFIELD.CITY && azCities && (
										<div className="max-h-[99px] mt-2 overflow-y-auto text-white font-FireSansRegular text-[14px]">
											Vorschläge: <ul>{getAzList(azCities, AZFIELD.CITY)}</ul>
										</div>
									)}
									{itemKey === AZFIELD.STREET && azStreets && (
										<div className="max-h-[99px] mt-2 overflow-y-auto text-white font-FireSansRegular text-[14px]">
											Vorschläge: <ul>{getAzList(azStreets, AZFIELD.STREET)}</ul>
										</div>
									)}
								</>
							)}
						</>
					)}
				</>
			)
		}
	}

	return (
		<>
			<form noValidate autoComplete="off" onSubmit={formSubmit} className={"max-w-3xl mx-auto "}>
				<PrefillForm />
				{/*  //TODO: */}
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-12 p-4 ">
					{formState &&
						Object.keys(formState).map((itemKey, index) => {
							return (
								<>
									{itemKey == "firstname" && <h3 className="h3 text-white pb-3 text-center lg:col-span-2 text-[25px] mb-12 ">Deine Adresse</h3>}
									<div
										key={"frrFormDef" + index}
										className={
											formState[itemKey].className +
											(itemKey == "CODEINSTRUCTIONS" ? " lg:col-span-2" : "") +
											(itemKey == "code5" ? " lg:col-span-2 lg:max-w-[344px] lg:translate-x-[50%]" : "") +
											(itemKey == "captcha" ? " lg:col-span-2 lg:max-w-[344px] lg:translate-x-[50%]" : "")
										}
									>
										{getField(itemKey)}
									</div>
								</>
							)
						})}
				</div>
				{afterFormFieldsBlock && afterFormFieldsBlock}
				{(errorMsg || "") !== "" && showValidation && <Alert className="mt-12">{errorMsg}</Alert>}
				{process.env.NODE_ENV === "development" && (
					<div className="my-12 border-dashed border-white border-2 p-8 bg-black rounded-3xl shadow-xl">
						<h2 className="h2">Debug - Form</h2> <p className="text-white">{JSON.stringify(formState).replaceAll(',"', ', "')}</p>
					</div>
				)}
				<div className="text-center pt-8">
					<button type="submit" className="btn-nosize-red uppercase translate-y-[25px] max-w-[75%] mx-auto">
						jetzt gratis bestellen!
					</button>
				</div>
				{afterFormSubmitBlock && afterFormSubmitBlock}
			</form>

			{codeInstructionDialogOpen && ( //TODO:
				<Popup close={() => setCodeInstructionDialogOpen(false)} btnStyle={"btn mt-6 "}>
					{popupInstruktions}
				</Popup>
			)}
		</>
	)
}

export default DynamticForm
