import { useContext, useEffect, useState } from "react"
import { BLACKLIST, STEP, LOWERCASEWORDS } from "../../config"
import { getEnvConfig } from "../../env"
import { Context } from "../../store/store"
import SvgFontElement from "../elements/SvgFont"
interface CompositonProps {
	activeStep?
	iconStep?
}

const Composition: React.FC<CompositonProps> = ({ activeStep, iconStep }) => {
	interface splittedName {
		name?: string
		capitalize?: boolean
	}

	const { globalState, dispatch } = useContext(Context)
	const env = getEnvConfig()
	const [tmpCityName, setTmpCityName] = useState<string>("dein Name")
	const [newUserName, setNewUserName] = useState<string>("")
	const [tmpCityNameSplitted, setTmpCityNameSplitted] = useState<Array<splittedName>>([])
	const [iconOffset, setIconOffset] = useState<Number>(0)
	const [isFontShifted, setIsFontShifted] = useState<boolean>(false)

	const YCityName = {
		MIDDLE: { cls: "top-[50%]", value: 50 },
		TOP: { cls: "top-[20%]", value: 20 },
		BOTTOM: { cls: "top-[80%]", value: 80 },
	}
	useEffect(() => {
		if (globalState.userNameStatus === BLACKLIST.UNKNOWN) {
			setTmpCityName("wird geprüft")
		} else if (globalState.userNameStatus === BLACKLIST.BLOCKED) {
			setTmpCityName("")
		} else {
			setTmpCityName(globalState.userName || "dein Name")
		}

		// checkFontShifting
		if (activeStep !== STEP.CITYNAME) {
			if (iconStep !== 0 && globalState.iconOne === "none") {
				setIsFontShifted(false)
			} else {
				setIsFontShifted(true)
			}
		} else {
			setIsFontShifted(false)
		}

		// eslint-disable-next-line
	}, [globalState.userName, tmpCityName])

	function setImageOffset() {
		setTimeout(function() {
			const doc: any = document
			const textlength = doc.querySelector("svg text").getComputedTextLength()
			setIconOffset(((100 / 332) * textlength) / 2)
		}, 300)
	}
	return (
		<div className="mx-auto bg-[url('/src/bgimages/knoppers/tasse-plain.png')] pt-[250px] w-[350px] h-[350px] lg:w-[425px] lg:h-[350px] lg:pt-[75%] pb-[8%] container bg-no-repeat bg-contain bg-center  relative  translate-x-[20px] lg:translate-x-[0] lg:max-w-full mt-24">
			<div className="lg:max-w-[75%]">
				<ul className="relative pb-[6%] ">
					{globalState.reference && (
						<li className="absolute top-0 left-0 w-full h-full  ">
							<div
								className={
									"w-full pb-[37.65%] bg-cover bg-center after:content-[''] after:w-[100%] after:h-[100%] after:absolute after:bg-[url('/src/bgimages/wasserzeichen.png')] after:bg-cover"
								}
								style={{
									backgroundImage: "url(" + env.apibase + "previewBackground.php?file=" + globalState.reference + ")",
								}}
							></div>
						</li>
					)}

					<li className="top-0 left-0 w-full h-full ">
						{activeStep !== STEP.CITYNAME && globalState.iconTwo !== "none" && (
							<img
								src={process.env.PUBLIC_URL + "/images/icons/" + globalState.iconTwo + ".png"}
								className="animate-iconFadeIn absolute left-[4%] top-[6%] w-[10%] h-auto "
								alt=""
							/>
						)}
						{activeStep !== STEP.CITYNAME && globalState.iconTwo === "none" && iconStep === 1 && (
							<img src={process.env.PUBLIC_URL + "/images/icons/placeholder.png"} className="animate-iconFadeIn absolute left-[4%] top-[6%] w-[10%] h-auto " alt="" />
						)}
					</li>

					<li className="top-0 left-0 w-full h-full">
						{activeStep !== STEP.CITYNAME && globalState.iconThree !== "none" && (
							<img
								src={process.env.PUBLIC_URL + "/images/icons/" + globalState.iconThree + ".png"}
								className="animate-iconFadeIn absolute right-[4%] top-[6%] w-[10%] h-auto "
								alt=""
							/>
						)}
						{activeStep !== STEP.CITYNAME && globalState.iconThree === "none" && iconStep === 2 && (
							<img src={process.env.PUBLIC_URL + "/images/icons/placeholder.png"} className="animate-iconFadeIn absolute right-[4%] top-[6%] w-[10%] h-auto " alt="" />
						)}
					</li>
					<li>
						<p
							className={
								"absolute skew-y-[-6deg] -rotate-[0deg] -translate-x-[30px] md:-translate-x-[35px] lg:translate-x-[2px] self-center justify-center text-[#372177] font-KnoppersFont -bottom-[26px] md:-bottom-[30px] lg:bottom-[33px] lg:-top-[130px] outline-8 " +
								(globalState.userName.length < 5 ? " text-[42px] md:text-[45px] lg:text-[58px]" : "") +
								(globalState.userName.length < 7 ? " text-[38px] md:text-[42px] lg:text-[52px]" : "") +
								(globalState.userName.length < 10 ? " text-[38px] md:text-[36px] lg:text-[42px]" : "") +
								(globalState.userName.length >= 10 ? " text-[28px] md:text-[32px] lg:text-[32px]" : "")
							}
						>
							{globalState.userNameStatus === BLACKLIST.ALLOWED && globalState.userName !== "" ? (
								<span className="name-wrap">
									<SvgFontElement name={globalState.userName} />
								</span>
							) : (
								<span className="name-wrap opacity-25">
									<SvgFontElement name="Name" />
								</span>
							)}
						</p>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Composition
