interface AlertProps {
	children?: any
	className?: any
}

const Alert: React.FC<AlertProps> = ({ children, className }) => {
	return (
		<section
			className={"bg-blue text-center text-white p-2 my-4 rounded-[9px] max-w-[750px] mx-auto text-[14px]" + className}
			dangerouslySetInnerHTML={{ __html: children }}
		></section>
	)
}

export default Alert
