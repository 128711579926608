interface RadioProps {
	props?: any
	className?: string | null
	label?: any
}

const ElemRadio: React.FC<RadioProps> = ({ className, props, label }) => {
	return (
		<label className={"relative block cursor-pointer bg-white h-[60px] leading-[65px] font-Soleil font-bold rounded " + (className || "")}>
			<input className={"w-2 h-2 hidden absolute"} {...props} type="radio" value={props.value} />
			<div className=" text-center text-[18px]  text-black ">{label}</div>
		</label>
	)
}

export default ElemRadio
