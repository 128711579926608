import { AZFIELD } from "./AZ"

export enum INPUT_TYPES {
	COMPONENT = "component",
	TEXT = "text",
	EMAIL = "email",
	SELECT = "select",
	DATE = "date",
	CHECKBOX = "checkbox",
	RADIO = "radio",
	CODES = "codes",
	NUMBER = "number",
}

const formRegEx = {
	// These RegEx below throw errors – might be more appropriate though.
	// eslint-disable-next-line
	alpha: "/^[A-Za-zäöüßãâåàáäæçéèêëìíîïłñôõóòøúûÿıÄÖÜÃÂÅÀÁÄÆÇÉÈÊËÌÍÎÏŁÑÔÕÓÒØÚÛŸI0-9]+[A-Za-zäöüßãâåàáäæçéèêëìíîïłñôõóòøúûÿıÄÖÜÃÂÅÀÁÄÆÇÉÈÊËÌÍÎÏŁÑÔÕÓÒØÚÛŸI0-9s-.]*.?/",
	// eslint-disable-next-lin
	email: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
	numerical: "^[0-9]*$",
	// eslint-disable-next-line
	birthdate: "^([0-2][0-9]|(3)[0-1])(.)(((0)[0-9])|((1)[0-2]))(.)d{4}$",
}

export const formDefinition: any = {
	// // {
	// // 	type: INPUT_TYPES.COMPONENT,
	// // 	component: <h3 className="h3 text-green countryHideOnSelect pb-4 pt-12">In welches Land sollen Deine Schuber verschickt werden?*</h3>,
	// // },
	// {
	// 	type: INPUT_TYPES.RADIO,
	// 	name: "countryselection",
	// 	errorMessage: "Bitte wähle ein Land aus.",
	// 	inputProps: {
	// 		name: "countryselection",
	// 	},
	// 	options: [
	// 		{ value: "de", label: "Deutschland" },
	// 		{ value: "at", label: "Österreich" },
	// 	],
	// 	// className: "countryHideOnSelect ",
	// },

	CODEINSTRUCTIONS: {
		type: INPUT_TYPES.COMPONENT,
		component: "CODEINSTRUCTIONS",
	},
	code1: {
		type: INPUT_TYPES.CODES,
		label: "Code 1",
		name: "code1",
		errorMessage: "Der eingegebene Code ist nicht korrekt.",
		inputProps: {
			placeholder: "Dein Code *",
			required: true,
			minLength: 10,
			maxLength: 10,
		},
	},
	code2: {
		type: INPUT_TYPES.CODES,
		label: "Code 2",
		name: "code2",
		errorMessage: "Der eingegebene Code ist nicht korrekt.",
		inputProps: {
			placeholder: "Dein Code *",
			required: true,
			minLength: 10,
			maxLength: 10,
		},
	},
	code3: {
		type: INPUT_TYPES.CODES,
		label: "Code 3",
		name: "code3",
		errorMessage: "Der eingegebene Code ist nicht korrekt.",
		ref: "code3",
		inputProps: {
			placeholder: "Dein Code *",
			required: true,
			minLength: 10,
			maxLength: 10,
		},
	},
	code4: {
		type: INPUT_TYPES.CODES,
		label: "Code 4",
		name: "code4",
		ref: "code4",
		errorMessage: "Der eingegebene Code ist nicht korrekt.",
		inputProps: {
			placeholder: "Dein Code *",
			required: true,
			minLength: 10,
			maxLength: 10,
		},
	},
	code5: {
		type: INPUT_TYPES.CODES,
		label: "Code 5",
		name: "code5",
		ref: "code5",
		errorMessage: "Der eingegebene Code ist nicht korrekt.",
		inputProps: {
			placeholder: "Dein Code *",
			required: true,
			minLength: 10,
			maxLength: 10,
		},
	},

	firstname: {
		type: INPUT_TYPES.TEXT,
		label: "Vorname",
		name: "firstname",
		errorMessage: "Bitte Vornamen eingeben.",
		inputProps: {
			required: true,
			pattern: formRegEx.alpha,
			minLength: 1,
		},
	},
	lastname: {
		type: INPUT_TYPES.TEXT,
		label: "Nachname",
		name: "lastname",
		errorMessage: "Bitte Nachnamen eingeben.",
		inputProps: {
			minLength: 1,
			pattern: formRegEx.alpha,
			required: true,
		},
	},
	[AZFIELD.ZIP]: {
		type: INPUT_TYPES.NUMBER,
		label: "PLZ",
		name: AZFIELD.ZIP,
		errorMessage: "Das Feld muss 5 Zahlen enthalten.",
		inputProps: {
			pattern: formRegEx.numerical,
			required: true,
			autoComplete: "off",
			leave: "no",
		},
	},
	[AZFIELD.CITY]: {
		type: INPUT_TYPES.TEXT,
		label: "Ort",
		name: AZFIELD.CITY,
		errorMessage: "Bitte Ort eingeben.",
		inputProps: {
			minLength: 1,
			required: true,
			pattern: formRegEx.alpha,
			autoComplete: "off",
			leave: "no",
		},
	},

	[AZFIELD.STREET]: {
		type: INPUT_TYPES.TEXT,
		label: "Straße",
		name: AZFIELD.STREET,
		errorMessage: "Bitte Straße eingeben.",
		inputProps: {
			minLength: 1,
			pattern: formRegEx.alpha,
			required: true,
			autoComplete: "off",
			leave: "no",
		},
	},
	housenr: {
		type: INPUT_TYPES.TEXT,
		label: "Hausnummer",
		name: "housenr",
		errorMessage: "Bitte Hausnummer eingeben.",
		inputProps: {
			minLength: 1,
			maxLength: 10,
			required: true,
		},
	},

	country: {
		type: INPUT_TYPES.TEXT,
		label: "Land",
		name: "country",
		errorMessage: "Das Feld darf nicht leer sein oder das Format ist ungültig.",
		inputProps: {
			minLength: 1,
			required: true,
		},
	},
	email: {
		type: INPUT_TYPES.EMAIL,
		label: "E-Mail-Adresse",
		name: "email",
		errorMessage: "Das Feld darf nicht leer sein oder das Format ist ungültig.",
		inputProps: {
			minLength: 1,
			pattern: formRegEx.email,
			required: true,
		},
	},
	captcha: {
		type: INPUT_TYPES.TEXT,
		name: "captcha",
		errorMessage: "Das Feld darf nicht leer sein oder der Code ist ungültig",
		inputProps: {
			minLength: 2,
			maxLength: 10,
			required: true,
		},
	},
}

export const GLOBAL_ERROR_MSG = `Bei der Übertragung Deiner Daten ist ein Fehler aufgetreten. Bitte versuche es erneut. Sollten die Probleme weiterhin bestehen, kannst Du dich an unser Service-Team wenden. Du erreichst uns per E-Mail unter staedteliebe@nutella.de. Bitte entschuldige die Unannehmlichkeiten.`
