import { useContext, useEffect, useState } from "react"
import { getEnvConfig } from "../../env"
import ElemInput from "./fields/Input"
import { Context } from "../../store/store"

const SimpleCaptcha = ({ formElementChanged, validation, captchaInputValue, setCaptchaIdent }) => {
	const env = getEnvConfig()
	const { globalState, dispatch } = useContext(Context)
	const [captchaImg, setCaptchaImg]: any = useState("")

	const toDataURL = url => {
		return fetch(url)
			.then(response => {
				response.headers.forEach(function(value, key) {
					if (key === "x-captcha-ident") {
						setCaptchaIdent(value)
					}
					if (key === "session-id") {
						dispatch({
							type: "SET_UPDATE_STATE",
							key: "sessionID",
							value: value,
						})
					}
				})

				return response.blob()
			})
			.then(
				blob =>
					new Promise((resolve, reject) => {
						const reader = new FileReader()
						reader.onloadend = () => resolve(reader.result)
						reader.onerror = reject
						reader.readAsDataURL(blob)
					})
			)
	}

	useEffect(() => {
		reloadCaptcha()
		// eslint-disable-next-line
	}, [])

	function reloadCaptcha() {
		toDataURL(env.apibase + "captcha.php?cv=" + Date.now()).then(dataUrl => {
			setCaptchaImg(dataUrl)
			dispatch({
				type: "SET_UPDATE_STATE",
				key: "captcha",
				value: "",
			})
		})
	}

	return (
		<div>
			<div>
				<p className="mb-4 mt-12 font-Soleil grid grid-cols-2 gap-4 items-center mx-auto">
					<div>
						<img src={captchaImg} className="rounded-lg w-full" alt="Captchaimage" />
					</div>
					<div className="text-xl leading-xl font-Soleil text-brownDark ">
						<div className="underline cursor-pointer text-[16px] text-white js-reloadCaptcha" onClick={reloadCaptcha}>
							Captcha neu laden
						</div>
					</div>
				</p>

				<ElemInput
					label={""}
					props={{
						value: captchaInputValue,
						required: "required",
						minLength: 2,
						maxLength: 10,
						onChange: e => formElementChanged(e, "captcha"),
						id: "captcha",
						name: "captcha",
						type: "text",
					}}
					hasError={validation ? true : false}
					className="col-span-2 md:col-span-1"
					errMsg={validation["isInvalid_captcha_errMsg"] || "Bitte Captcha korrekt eingeben. Bitte Groß- und Kleinschreibung beachten."}
				/>
			</div>
		</div>
	)
}

export default SimpleCaptcha
