interface CountryBtnProps {
  props?: any;
  className?: string | null;
  label?: string | null;
  onClick?: any;
  country?: any;
}

const CountryBtn: React.FC<CountryBtnProps> = ({
  props,
  className,
  label,
  onClick,
  country,
}) => {
  return (
    <a
      onClick={onClick}
      className={
        "btn-nosize-white grid grid-cols-[1fr_30px_20px] tracking-[2px] items-center mb-6 " +
        className
      }
      {...props}
    >
      {label}
      <img
        src={process.env.PUBLIC_URL + "/images/knoppers/" + country + ".svg"}
      />
      <img
        src={process.env.PUBLIC_URL + "/images/knoppers/arrow-darkblue.svg"}
      />
    </a>
  );
};
export default CountryBtn;
