import React, { useEffect } from "react"
import { SVGArrowLeft } from "../components/elements/SVG"

const Error: React.FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<section className="headerAlignment">
			<div className="text-center pt-5 py-10">
				<h2 className="h3 ">Hier ist etwas schief gelaufen...</h2>
				<a href="/" className="inline-block btn   mt-8">
					Zurück zur Aktion <SVGArrowLeft />
				</a>
			</div>
		</section>
	)
}
export default Error
