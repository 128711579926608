const Spinner = (props?) => {
  return (
    <svg
      className="animate-spinner inline-block"
      viewBox="0 0 50 50"
      width={props.width ? props.width : "50"}
      height={props.height ? props.height : "50"}
    >
      <circle
        className={
          "animate-spinnerDash " + (props.color ? props.color : "text-blue")
        }
        cx={25}
        cy={25}
        r={20}
        fill="none"
        strokeLinecap="round"
        stroke="#2B6BB0"
        strokeWidth={5}
      />
    </svg>
  );
};

export default Spinner;
