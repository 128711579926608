import React from "react"

interface AccordionItemProps {
	children?: any
	headline?: string
	num?: string
}

const AccordionItem: React.FC<AccordionItemProps> = ({ children, headline, num }) => {
	return (
		<label className="block bg-white  py-4  pr-2 relative border-b-2 border-lightgray">
			<input type={"checkbox"} className="peer opacity-0 h-0 absolute" />
			<div className="grid grid-cols-[10%_75%_15%] items-center">
				<h4 className="h3 inline-block text-[16px] text-darkblue">
					<span className="">{num}</span>
				</h4>
				<h5 className="h3 font-Barlow text-[16px] font-bold text-darkblue uppercase">{headline}</h5>
				<svg className="inline-block right-8 top-7 w-8 peer-checked:-rotate-180 self-center" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon">
					<path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" className="fill-darkblue" />
				</svg>
			</div>
			<div className="ml-8 sm:ml-0 transition-all text-[16px] max-h-0 duration-500 peer-checked:lg:max-h-[1700px] peer-checked:max-h-[2700px] peer-checked:pt-6 peer-checked:pb-5 ease-in-out overflow-x-hidden">
				{children}
			</div>
		</label>
	)
}
export default AccordionItem
