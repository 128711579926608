import { useEffect } from "react"
import { Link } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"

interface FaqProps {
	showMaxItems?: any
}

const Faq: React.FC<FaqProps> = ({ showMaxItems = 0 }) => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "FAQ", link: label })
		} catch (e) {}
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	const data = [
		{
			headline: "Die Aktion",
			content: [
				{
					headline: <>Worum geht es bei der Knoppers Tassenaktion?</>,
					text: (
						<>
							Im Rahmen unserer Aktion kannst du dir deine personalisierte Knoppers Tasse gratis bestellen. Einfach fünf Knoppers Aktionspackungen kaufen und die fünf Codes unter{" "}
							<Link to="/" target="_blank" className="underline" rel="noopener noreferrer" onClick={() => track("Knoppers")}>
								www.knoppers-tasse.de
							</Link>{" "}
							im Aktionszeitraum einlösen. Die Porzellantasse mit Henkel ist mit dem Spruch „Läuft bei dir“ und einem von dir gewählten Vor-, Nach-, Spitz- oder Kosenamen (max. 12
							Zeichen) bedruckt. Du erhältst dann innerhalb von 8 Wochen deine personalisierte Knoppers Tasse gratis per Post zugeschickt.
						</>
					),
				},
				{
					headline: <>In welchem Zeitraum kann ich an der Aktion teilnehmen?</>,
					list: [
						<>
							Die Aktion startet am 06. Februar 2024 und endet am 21. Mai 2024. Nach diesem Zeitpunkt verlieren die Aktionscodes ihre Gültigkeit und die Bestellung einer
							personalisierten Knoppers Tasse ist nicht mehr möglich.
						</>,
					],
				},
				{
					headline: <>Wer kann an der Aktion teilnehmen?</>,
					text: <>Teilnahmeberechtigt ist jede natürliche Person ab 18 Jahren innerhalb Deutschlands und Österreichs.</>,
				},
				{
					headline: <>Wie kann ich an der Aktion teilnehmen?</>,
					text: (
						<>
							<p className="mt-4 text-[16px]">1. Fünf Knoppers Aktionspackungen mit insgesamt fünf Aktionscodes im Aktionszeitraum kaufen. </p>
							<p className="mt-4 text-[16px]">
								{" "}
								2. Auf die Website{" "}
								<Link to="www.knoppers-tasse.de" target="_blank" className="underline" rel="noopener noreferrer" onClick={() => track("Knoppers")}>
									www.knoppers-tasse.de
								</Link>{" "}
								gehen und mit der Personalisierung der Knoppers Tasse starten.
							</p>
							<p className="mt-4 text-[16px]">3. Unter „Jetzt Gestalten“ deinen Wunschnamen mit max. 12 Zeichen zur Personalisierung der Knoppers Tasse eingeben.</p>
							<p className="mt-4 text-[16px]">
								4. Aktionscodes und personenbezogene Daten eingeben und durch Klicken auf „Jetzt gratis bestellen“ alle Informationen direkt übermitteln. Weitere personenbezogene
								Daten, die eingegeben werden müssen, sind: Vor- und Nachname, Adresse und E-Mail-Adresse.
							</p>
							<p className="mt-4 text-[16px]">5. Innerhalb von 8 Wochen erhältst du deine personalisierte Knoppers Tasse gratis per Post an die angegebene Adresse. </p>
						</>
					),
				},
				{
					headline: <>Wie lauten die Teilnahmebedingungen?</>,
					text: (
						<>
							Die Teilnahmebedingungen findest du{" "}
							<Link className="underline" to="/teilnahmebedingungen" onClick={() => track("Teilnahmebedingungen")}>
								hier
							</Link>
							.
						</>
					),
				},
			],
		},
		{
			headline: "Aktionspackungen",
			content: [
				{
					headline: <>Wo finde ich die Aktionspackungen im Handel?</>,
					text: (
						<>
							Die Knoppers Aktionspackungen sind ab ca. Februar 2024 in Deutschland und Österreich bundesweit und solange der Vorrat reicht im Handel verfügbar. Abbildungen und
							Informationen zu den teilnehmenden Aktionspackungen können unter{" "}
							<Link to="https://www.knoppers-tasse.de" target="_blank" className="underline" rel="noopener noreferrer" onClick={() => track("Knoppers")}>
								www.knoppers-tasse.de
							</Link>{" "}
							eingesehen werden. Da wir selbst keinen Einfluss auf den Abverkauf der Ware haben, können wir keine Händler nennen, die Aktionspackungen vorrätig haben. Wir
							empfehlen, in den Supermärkten auch immer einen Blick in die Gänge neben den Regalen zu werfen, da Aktionspackungen oft separat präsentiert werden. Bitte beachte,
							dass die Aktionspackungen außen deutlich gekennzeichnet sind.
						</>
					),
				},
				{
					headline: <>Wie sehen die Aktionspackungen aus?</>,
					text: (
						<>
							Die Aktionspackungen sind durch einen deutlichen Hinweis auf die Aktion zu erkennen. Die Aktionscodes befinden sich auf der Innenseite der Aktionspackungen von
							Knoppers Classic 8er, Knoppers Black & White 8er, Knoppers Erdnuss 8er und Knoppers Joghurt 8er. Hier findest du Bilder von unseren Aktionspackungen. In jeder
							Aktionspackung befindet sich 1 Aktionscode.
							<ul>
								<li className="block">
									<img className="w-[300px]" src={process.env.PUBLIC_URL + "/images/knoppers/KNO_8_von_oben.png"}></img>
									<p className="pl-4">Knoppers Classic </p>
									<p className="pl-4">8er, 200 g</p>
								</li>
								<li className="block">
									<img className="w-[300px]" src={process.env.PUBLIC_URL + "/images/knoppers/KNO_PEA_8_von_oben.png"}></img>
									<p className="pl-4">Knoppers Erdnuss </p>
									<p className="pl-4">8er, 200 g </p>
								</li>
								<li className="block">
									<img className="w-[300px]" src={process.env.PUBLIC_URL + "/images/knoppers/KNO_JOG_8_von_oben.png"}></img>
									<p className="pl-4">Knoppers Joghurt </p>
									<p className="pl-4">8er, 200 g</p>
								</li>
								<li className="block">
									<img className="w-[300px]" src={process.env.PUBLIC_URL + "/images/knoppers/KNO_BW_8_von_oben.png"}></img>
									<p className="pl-4">Knoppers Black & White</p>
									<p className="pl-4"> 8er, 200 g</p>
								</li>
							</ul>
						</>
					),
				},
			],
		},
		{
			headline: "Bestellung",
			content: [
				{
					headline: <>Kann ich meine Einstellungen speichern und den Prozess später abschließen?</>,
					text: <>Nein, leider ist dies aus technischen Gründen nicht möglich. Der Prozess dauert aber auch nur wenige Minuten.</>,
				},
				{
					headline: <>Kann ich auch mehrmals an der Aktion teilnehmen?</>,
					text: <>Natürlich! Du kannst so viele Aktionspackungen kaufen und Codes einlösen, wie du möchtest. Jeden Code kannst du allerdings nur einmal einlösen.</>,
				},

				{
					headline: <>Wo genau finde ich den Code auf meiner Aktionspackung?</>,
					text: (
						<>
							Die Aktionspackungen sind durch einen deutlichen Hinweis auf die Aktion zu erkennen. Die 10-stelligen Aktionscodes befinden sich auf der Innenseite der
							Aktionspackungen von Knoppers Classic 8er, Knoppers Black & White 8er, Knoppers Erdnuss 8er und Knoppers Joghurt 8er.{" "}
						</>
					),
				},
				{
					headline: <>Ich kann meinen Code nicht lesen.</>,
					text: (
						<>
							Wenn du den Code in der Aktionspackung nicht lesen kannst, wende dich bitte an unseren Kundenservice: Bitte fotografiere hierfür deinen Code und sende uns das Foto an
							folgende E-Mail-Adresse:{" "}
							<a className="underline" href="mailto:mail@knoppers-tasse.de" onClick={() => track("Email")}>
								mail@knoppers-tasse.de
							</a>
							. Das Serviceteam hilft dir dann so schnell wie möglich weiter.
						</>
					),
				},
				{
					headline: <>Mein Code funktioniert nicht, was kann ich tun?</>,
					text: (
						<>
							Wenn du sicher bist, dass du den Code nicht aus Versehen schon einmal benutzt hast, und es sich auch nicht um einen Tippfehler handelt, schicke uns bitte ein Foto
							deines Codes per E-Mail an{" "}
							<a className="underline" href="mailto:mail@knoppers-tasse.de" onClick={() => track("Email")}>
								mail@knoppers-tasse.de
							</a>{" "}
							zur Prüfung zu.
						</>
					),
				},
				{
					headline: <>Welche Kosten sind mit der Erstellung der Tasse verbunden? </>,
					text: <>Das Erstellen und der Versand der personalisierten Knoppers Tasse ist gratis. Um an der Aktion teilnehmen zu können, musst du fünf Aktionspackungen kaufen.</>,
				},
				{
					headline: <>Welche technischen Voraussetzungen braucht mein Eingabegerät?</>,
					text: (
						<>
							Um deine personalisierte Knoppers Tasse zu bestellen, muss dein Eingabegerät keine bestimmten technischen Voraussetzungen erfüllen. Wichtig ist nur, dass du über
							einen Internetzugriff verfügst. Unsere Bestellwebsite ist mit allen gängigen Browsern und Betriebssystemen kompatibel.
						</>
					),
				},
				{
					headline: <>Kann ich auch teilnehmen, wenn ich kein Internet habe?</>,
					text: (
						<>
							Nein, eine Teilnahme ist ohne Zugang zum Internet nicht möglich. Aber natürlich kann eine andere Person für dich die fünf Codes einlösen und deine personalisierte
							Knoppers Tasse bestellen.
						</>
					),
				},
			],
		},
		{
			headline: "Personalisierung",
			content: [
				{
					headline: <>Welche Möglichkeiten habe ich, um meine Tasse zu personalisieren?</>,
					text: (
						<>
							Du gibst einen Vor-, Nach-, Spitz- oder Kosenamen in das dafür vorgesehene Feld ein. Wenn der gewünschte Name unserem System bereits bekannt ist, erscheint er
							unmittelbar in der Vorschau. Namen, die unserem System nicht bekannt sind, werden von uns nach Abschluss der Bestellung individuell geprüft. Der Vor-, Nach-, Spitz-
							oder Kosenamen darf max. 12 Zeichen haben.
						</>
					),
				},
				{
					headline: <>Was passiert, wenn mein Name in der Prüfung ist?</>,
					text: (
						<>
							Namen, die unserem System nicht bekannt sind, werden von uns nach Abschluss der Bestellung individuell geprüft. Nach erfolgter Prüfung erhältst du eine E-Mail, in der
							du über die Prüfentscheidung in Kenntnis gesetzt wirst. Die Prüfung des Wunschnamens kann bis zu 2 Werktage in Anspruch nehmen. Im Falle einer unvorhersehbar hohen
							Anzahl an Anfragen kann sich die Prüfzeit verzögern. Wird ein eingegebener Name aus der Bestellung abgelehnt, hat der Teilnehmer die Möglichkeit diesen durch einen
							anderen Namen zu ersetzen. Alle fünf Aktionscodes aus der Bestellung werden hierfür wieder freigeschaltet und der Bestellprozess muss noch einmal durchgeführt werden.
						</>
					),
				},
				{
					headline: <>Mein Name wurde nicht akzeptiert, warum?</>,
					text: (
						<>
							Bei der Namenseingabe sind ausschließlich Vor-, Nach-, Spitz- oder Kosenamen vorgesehen. Wir bitten dich um Verständnis, dass wir jede Eingabe prüfen müssen, um
							rassistische, anstößige oder illegale Formulierungen sowie solche, die gegen das Urheber- oder Markenrecht oder unsere Teilnahmebedingungen verstoßen, zu vermeiden.
							Aus Platzgründen können leider keine Namen, die aus mehr als 12 Zeichen bestehen, verwendet werden.
						</>
					),
				},
				{
					headline: <>Warum kann ich keinen Namen oder Kosenamen eingeben, der mehr als 12 Zeichen hat?</>,
					text: <>Leider passen nicht mehr als 12 Zeichen auf die Tasse.</>,
				},
			],
		},
		{
			headline: "Nach der Bestellung",
			content: [
				{
					headline: <>Wie kann ich sicher sein, dass meine Bestellung erfolgreich war?</>,
					text: (
						<>
							Du erhältst innerhalb von 30 Minuten nach deiner Bestellung eine Bestätigung an die von dir angegebene E-Mail-Adresse. Sobald deine Tasse versandt wurde, bekommst du
							eine erneute Bestätigung per E-Mail.
						</>
					),
				},
				{
					headline: <>Kann ich eine Bestellung ändern oder stornieren?</>,
					text: (
						<>
							Bitte wende dich in diesem Fall montags bis freitags von 8:30 bis 17:30 Uhr an unseren Kundenservice unter +49 (0)30 / 47 06 00 68 oder per E-Mail an{" "}
							<a className="underline" href="mailto:mail@knoppers-tasse.de" onClick={() => track("Email")}>
								mail@knoppers-tasse.de
							</a>
							. Dein Anliegen wird dann individuell geprüft.
						</>
					),
				},
				{
					headline: <>Bei mir hat sich die Adresse geändert. Wem teile ich die Änderung mit?</>,
					text: (
						<>
							Wenn sich bei dir die Anschrift geändert hat, sende bitte eine E-Mail an{" "}
							<a className="underline" href="mailto:mail@knoppers-tasse.de" onClick={() => track("Email")}>
								mail@knoppers-tasse.de
							</a>{" "}
							mit Angabe deines vollständigen Namens, E-Mail-Adresse und neuer Adresse.{" "}
						</>
					),
				},
				{
					headline: <>Wie lange dauert es, bis meine Knoppers Tasse bei mir zu Hause ankommt?</>,
					text: (
						<>
							Die Zustellung erfolgt in der Regel innerhalb von 8 Wochen. Ein Versand ist nur an eine Adresse innerhalb Deutschlands oder Österreichs möglich. Die Richtigkeit der
							Adresse sollte dabei in jedem Fall überprüft werden. Deine personalisierte Knoppers Tasse wird per Post an die von dir angegebene Adresse verschickt.{" "}
						</>
					),
				},
				{
					headline: <>Kann ich den Status meiner Bestellung einsehen?</>,
					text: <>Du wirst von uns über deine angegebene E-Mail-Adresse über den Bestell- und Lieferstatus deiner Bestellung informiert.</>,
				},
				{
					headline: <>Entstehen Kosten, wenn ich die Knoppers Tasse zu mir nach Hause schicken lasse?</>,
					text: <>Nein, es fallen für dich keine Versandkosten an. </>,
				},
				{
					headline: <>Mit meiner Lieferung stimmt etwas nicht. Was kann ich tun?</>,
					text: (
						<>
							Bitte wende dich in diesem Fall montags bis freitags von 8:30 bis 17:30 Uhr an unseren Kundenservice unter +49 (0)30 / 47 06 00 68 oder per E-Mail an{" "}
							<a className="underline" href="mailto:mail@knoppers-tasse.de" onClick={() => track("Email")}>
								mail@knoppers-tasse.de
							</a>
							. Dein Anliegen wird dann individuell geprüft.
						</>
					),
				},
			],
		},
		{
			headline: "Datenschutz",
			content: [
				{
					headline: "Werden meine Daten an Dritte weitergegeben?",
					text: (
						<>
							Deine im Rahmen der Aktion angegebenen Daten werden von Storck ausschließlich zum Zwecke der Durchführung der Aktion und Erstellung deiner personalisierten Knoppers
							Tasse erhoben, gespeichert und genutzt - etwa zum Zwecke der Zustellung - und nicht an Dritte weitergegeben. Im Rahmen dieser Promotion arbeitet Storck mit seiner
							Tochtergesellschaft, der Storck Deutschland KG, Waldstraße 27, 13403 Berlin, sowie dem sorgfältig ausgewählten direct services Gütersloh GmbH, Reinhard-Mohn-Str. 300,
							33333 Gütersloh zusammen. Die für Storck tätigen Auftragsverarbeiter sind ordnungsgemäß auf die Einhaltung des Datenschutzes verpflichtet.
							<br /> Mehr dazu findest du in unseren{" "}
							<Link className="underline text-[16px]" to="/datenschutz" onClick={() => track("Datenschutz")}>
								Datenschutzerklärungen
							</Link>
							.
						</>
					),
				},
				{
					headline: "Werden meine Daten gespeichert und für weitere Aktionen verwendet?",
					text: (
						<>
							Nein, Storck nutzt die im Rahmen der Promotion verarbeiteten personenbezogenen Daten ausschließlich zum Zwecke der Durchführung und Abwicklung der Aktion. Eine
							weitergehende Nutzung durch Storck oder Dritte findet nicht statt.
						</>
					),
				},
				{
					headline: "Welche personenbezogenen Daten muss ich angeben?",
					text: (
						<>
							Zur Teilnahme an der Aktion benötigen wir deinen vollständigen Namen, deine E-Mail-Adresse und deine Adresse, an die wir später deine personalisierte Knoppers Tasse
							schicken können.
						</>
					),
				},
			],
		},
	]

	const getContent = () => {
		let count = 0
		return (
			<>
				<ul className="reset">
					{data.map(section => (
						<>
							<li className="h2 text-darkblue text-[24px] mt-12 mb-12">{section.headline}</li>
							<li>
								<div>
									{section.content!.map(cont => {
										count++
										if (showMaxItems == 0 || count <= showMaxItems) {
											return (
												<AccordionItem headline={cont.headline} num={count.toString()}>
													<>
														{cont.text && cont.text}
														{cont.list && (
															<ol>
																{Object.values(cont.list).map((val: any) => (
																	<li>{val}</li>
																))}
															</ol>
														)}
													</>
												</AccordionItem>
											)
										}
									})}
								</div>
							</li>
						</>
					))}
				</ul>
				<div>
					<h3 className="h3 mt-24 mb-12  text-darkblue text-center">Kontakt</h3>
					<p className="mb-24 text-[16px]">
						Deine Frage wurde noch nicht beantwortet? Dann sende eine E-Mail an{" "}
						<a className="underline" href="mailto:mail@knoppers-tasse.de" onClick={() => track("Email")}>
							mail@knoppers-tasse.de
						</a>{" "}
						oder wende dich telefonisch an unseren Kundenservice unter +49 (0)30 / 47 06 00 68. Unseren Kundenservice erreichst du montags bis freitags von 8:30 bis 17:30 Uhr.
					</p>
				</div>
			</>
		)
	}

	return (
		<section className="pt-12 lg:pt-24" id="faq">
			<div className="container px-8 max-w-4xl">
				<h1 className="h2 font-Barlow text-darkblue mb-8">FAQs</h1>
				{getContent()}
			</div>
		</section>
	)
}

export default Faq
