import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import Spacer from "./Spacer"
import { SVGArrowRight, SVGClose } from "./SVG"

const ErrorOverlay = ({ show = false, headline = "", text = "", buttonUrl = "", buttonText = "" }) => {
	const [overlayState, setOverlayState] = useState(show)

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "Overlay", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		setOverlayState(show)
	}, [show])
	return (
		<section className={"transition duration-150 ease-in-out fixed top-0 left-0 w-full h-full bg-black/30 z-[501] " + (overlayState ? "" : "hidden")}>
			<div className="w-96 absolute left-1/2 top-1/2 bg-white -translate-x-1/2 -translate-y-1/2 p-4">
				<div className="closing grid align-right justify-items-end text-right px-2 pt-6"></div>
				<div className="p-4 text-center">
					<p className="h2 uppercase text-[20px] text-darkblue font-bold font-FireSansCondensedExtraBold">{headline && headline}</p>
					<ul className="no-arrow text-[14px] mt-4">
						<li className="mb-4 items-center font-FireSansRegular  text-lightblue">
							<p dangerouslySetInnerHTML={{ __html: text }}></p>
						</li>
					</ul>
					<div className="text-center md:max-w-[600px] mx-auto mt-4 mb-2">
						{buttonUrl === "close" && (
							<a
								className="btn mt-4 uppercase w-full text-center"
								onClick={() => {
									setOverlayState(!show)
								}}
							>
								{" "}
								{buttonText && buttonText}
							</a>
						)}
						{buttonUrl !== "close" && (
							<Link to={buttonUrl && buttonUrl} className="btn mt-4 uppercase w-full text-center" onClick={() => track("Okay")}>
								{buttonText && buttonText}
							</Link>
						)}
					</div>
				</div>
			</div>
		</section>
	)
}

export default ErrorOverlay
