import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { BLACKLIST, STEP, LOWERCASEWORDS } from "../../config"
import { getEnvConfig } from "../../env"
import { Context } from "../../store/store"
import Alert from "../elements/Alert"
import ElemInput from "../elements/Input"
import Spinner from "../elements/Spinner"
import Composition from "./Composition"
import ErrorOverlay from "../elements/errorOverlay"
import UpScroll from "../Upscroll"

const CityName = ({ setCurrStep, activeStep }) => {
	const { globalState, dispatch } = useContext(Context)
	const navigate = useNavigate()
	// Blacklist
	const [loading, setLoading] = useState(false)
	const [errorBlackListMessage, setErrorBlackListMessage] = useState(BLACKLIST.NONE)
	const [hasUnallowedChars, setHasUnallowedChars] = useState(false)
	const [newUserName, setNewUserName] = useState<string>("")
	// Cityname
	const [userName, setuserName] = useState<String>("")
	const [blockedName, setBlockedName] = useState(false)
	const [unknownName, setUnknownName] = useState(false)

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "tasse-name", action: label })
		} catch (e) {}
	}

	useEffect(() => {
		dispatch({
			type: "SET_UPDATE_STATE",
			key: "userNameStatus",
			value: BLACKLIST.BLOCKED,
		})
		if (userName.length > 0) {
			const match = userName.match(/[A-Za-zäöüßéèàùÈÀÙÉ'\-+.&+,?!, \/]/g)
			if (!match || match.join("") !== userName) {
				setHasUnallowedChars(true)
			} else {
				setHasUnallowedChars(false)
			}
		} else {
			dispatch({
				type: "SET_UPDATE_STATE",
				key: "userName",
				value: "",
			})
		}
	}, [userName, setuserName])

	const submitText = e => {
		e.preventDefault()
		setBlockedName(false)
		setUnknownName(false)

		const allowedCallback = () => {
			dispatch({
				type: "SET_UPDATE_STATE",
				key: "userName",
				value: splitNameForCapitalize(userName),
			})
			dispatch({
				type: "SET_UPDATE_STATE",
				key: "userNameStatus",
				value: BLACKLIST.ALLOWED,
			})
			setLoading(false)
			setErrorBlackListMessage(BLACKLIST.NONE)
		}

		const unknownCallback = () => {
			dispatch({ type: "SET_UPDATE_STATE", key: "userName", value: userName })
			dispatch({
				type: "SET_UPDATE_STATE",
				key: "userNameStatus",
				value: BLACKLIST.UNKNOWN,
			})
			setLoading(false)
			setUnknownName(true)
			setErrorBlackListMessage(BLACKLIST.UNKNOWN)
			track("fehler - " + BLACKLIST.UNKNOWN)
		}
		const blockedCallback = () => {
			dispatch({ type: "SET_UPDATE_STATE", key: "userName", value: userName })
			dispatch({
				type: "SET_UPDATE_STATE",
				key: "userNameStatus",
				value: BLACKLIST.BLOCKED,
			})
			setLoading(false)
			setBlockedName(true)
			setErrorBlackListMessage(BLACKLIST.BLOCKED)
			track("fehler - " + BLACKLIST.BLOCKED)
		}

		if (hasUnallowedChars === false) {
			isTextValid(allowedCallback, unknownCallback, blockedCallback)
		}
	}

	const splitNameForCapitalize = usrName => {
		const splittedName = usrName.split(" ")
		let newUserName = ""

		splittedName.map(item => {
			if (LOWERCASEWORDS.includes(item)) {
				newUserName += item.toLowerCase() + " "
			} else {
				newUserName +=
					item
						.toLowerCase()
						.charAt(0)
						.toUpperCase() +
					item.toLowerCase().slice(1) +
					" "
			}
		})
		if (newUserName.includes("-")) {
			const removeMinusSign = newUserName.split("-")
			newUserName = ""
			removeMinusSign.map((item, index) => {
				if (LOWERCASEWORDS.includes(item)) {
					newUserName += item.toLowerCase() + ""
				} else {
					newUserName +=
						item
							.toLowerCase()
							.charAt(0)
							.toUpperCase() +
						item.toLowerCase().slice(1) +
						""
				}
				if (index === 0) {
					newUserName += "-"
				}
			})
		}
		return newUserName.trim()
	}

	const isTextValid = (allowedCallback = () => {}, unknownCallback = () => {}, blockedCallback = () => {}) => {
		const textTrimed = userName?.trim() || ""
		const env = getEnvConfig()
		setErrorBlackListMessage(BLACKLIST.NONE)

		if (textTrimed && !!textTrimed.length) {
			setLoading(true)
			setErrorBlackListMessage(BLACKLIST.NONE)

			const data = {
				title: textTrimed,
			}

			axios
				.post(env.apibase + "titleCheck.php?cv=" + Date.now(), { data })
				.then(res => {
					if (res.status === 200) {
						if (res.data.status === BLACKLIST.ALLOWED) {
							allowedCallback()
						} else if (res.data.status === BLACKLIST.UNKNOWN) {
							unknownCallback()
						} else if (res.data.status === BLACKLIST.BLOCKED) {
							blockedCallback()
						} else {
							blockedCallback()
						}
					} else {
						blockedCallback()
					}
				})
				.catch(error => {
					blockedCallback()
				})
		} else {
			setErrorBlackListMessage(BLACKLIST.EMPTY)
		}
	}

	function setposition(newPos) {
		dispatch({ type: "SET_UPDATE_STATE", key: "position", value: newPos })
	}

	return (
		<>
			<section>
				<section className="w-full h-[470px] lg:h-[670px] bg-[url('/src/bgimages/knoppers/hero-bg.png')] bg-cover bg-center white-brush-border-bottom">
					<div className="max-w-[1000px] px-4 mx-auto grid grid-cols-1 md:grid-cols-2 h-[100%] gap-4 lg:gap-20">
						<Composition activeStep={activeStep} />
						<div className=" mx-auto self-center -mt-[35px] lg:mt-0 z-[20] bg-white px-6 pt-6 pb-12 lg:px-12 lg:pt-12 bg-opacity-75 w-[330px] lg:w-[516px] drop-shadow-[0px_2px_3px_rgba(0,0,0,0.54)]">
							<h2 className="h2 text-darkblue text-[24px]lg:text-[46px] text-center lg:text-left mb-10 font-FireSansCondensedExtraBold">
								Gestalte Deine <br />
								Knoppers Tasse
							</h2>
							<form noValidate autoComplete="off" className="grid grid-cols-1 lg:grid-cols-[1fr_140px] gap-4 lg:gap-4" onSubmit={submitText}>
								<ElemInput
									key={"Name"}
									label={""}
									className={"mt-4"}
									props={{
										name: "tmpcityame",
										type: "text",
										onChange: e => setuserName(e.target.value),
										value: userName,
										pattern: "[A-Za-z]",
										maxLength: 12,
										minLength: 2,
										placeholder: "Dein Name",
										className:
											"shadow-[inset_0_2px_2px_0_rgba(0,0,0,0.15)] w-full rounded-[24px] px-4 text-sm font-Manrope text-black h-[40px] leading-[40px] placeholder-transparent outline-0 appearance-none bg-gray pt-0 mt-0",
									}}
									showLeftCount={true}
									// showValidation={validation.showValidation}
									hasError={errorBlackListMessage !== BLACKLIST.NONE && errorBlackListMessage !== BLACKLIST.ALLOWED && errorBlackListMessage !== BLACKLIST.UNKNOWN}
									errMsg={"Bitte gib einen gültigen Namen ein."}
								/>
								<div className="-mt-1">
									<button
										className={"btn-nosize uppercase " + (userName.length < 2 ? "opacity-50" : "")}
										onClick={() => track("anzeigen")}
										disabled={hasUnallowedChars || userName.length < 2 ? true : undefined}
									>
										Anzeigen
										<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block ml-2">
											<g clipPath="url(#clip0_172_1202)">
												<path
													d="M4.04286 1.18095C3.85953 1.36428 3.78096 1.62619 3.78096 1.8619C3.78096 2.04524 3.85953 2.30714 4.04286 2.54285L7.31667 5.86904L4.04286 9.24762C3.88572 9.40476 3.78096 9.66666 3.78096 9.92857C3.78096 10.1905 3.85953 10.3738 4.04286 10.6095C4.38334 11.0286 4.98572 11.0286 5.3262 10.6095L9.20239 6.55C9.38572 6.36666 9.46429 6.10476 9.46429 5.86904C9.46429 5.60714 9.38572 5.42381 9.20239 5.18809L5.3262 1.10238C5.16906 0.840473 4.90715 0.761902 4.72382 0.761902C4.54048 0.840473 4.2262 0.945235 4.04286 1.18095Z"
													fill="white"
												/>
											</g>
											<defs>
												<clipPath id="clip0_172_1202">
													<rect width="11" height="11" fill="white" transform="translate(0.899994 0.5)" />
												</clipPath>
											</defs>
										</svg>
									</button>
								</div>
							</form>
							{loading && (
								<div className="text-center mt-6">
									<Spinner />
								</div>
							)}
							{hasUnallowedChars && <Alert className="mb-4 text-[11px]">Ups, benutze bitte nur folgende Zeichen: abcdefghijklmnopqrstuvwxyzäöüßéèàù-/&',</Alert>}
							<div className={"grid grid-cols-1 lg:grid-cols-[75%_25%] gap-4 items-left"}>
								{globalState.userNameStatus !== BLACKLIST.BLOCKED && (
									<button
										className={
											(globalState.userNameStatus === BLACKLIST.ALLOWED || unknownName || globalState.userName === " " ? "" : "disabled") +
											" btn-nosize-red uppercase translate-y-[25px] lg:max-w-[200px] "
										}
										onClick={() => {
											globalState.userName ? navigate("/country") : navigate("/gestalten")
											track("weiter")
										}}
										disabled={globalState.userNameStatus === BLACKLIST.ALLOWED || globalState.userNameStatus === BLACKLIST.UNKNOWN ? undefined : true}
									>
										<span className="uppercase">Weiter</span>
										<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block ml-2">
											<g clipPath="url(#clip0_172_1202)">
												<path
													d="M4.04286 1.18095C3.85953 1.36428 3.78096 1.62619 3.78096 1.8619C3.78096 2.04524 3.85953 2.30714 4.04286 2.54285L7.31667 5.86904L4.04286 9.24762C3.88572 9.40476 3.78096 9.66666 3.78096 9.92857C3.78096 10.1905 3.85953 10.3738 4.04286 10.6095C4.38334 11.0286 4.98572 11.0286 5.3262 10.6095L9.20239 6.55C9.38572 6.36666 9.46429 6.10476 9.46429 5.86904C9.46429 5.60714 9.38572 5.42381 9.20239 5.18809L5.3262 1.10238C5.16906 0.840473 4.90715 0.761902 4.72382 0.761902C4.54048 0.840473 4.2262 0.945235 4.04286 1.18095Z"
													fill="white"
												/>
											</g>
											<defs>
												<clipPath id="clip0_172_1202">
													<rect width="11" height="11" fill="white" transform="translate(0.899994 0.5)" />
												</clipPath>
											</defs>
										</svg>
									</button>
								)}
							</div>
							<ErrorOverlay
								show={blockedName}
								headline="Diese Eingabe kann nicht verwendet werden."
								text="Bitte einen anderen Namen eingeben."
								buttonUrl="close"
								buttonText="Okay"
							/>
							<ErrorOverlay
								show={unknownName}
								headline="Deine Eingabe wird nach Abschluss der Bestellung geprüft."
								text="Dies kann einige Tage dauern. Anschließend wirst du per E-Mail über das Prüfergebnis informiert. Weitere Informationen findest du in unseren <a href='/teilnahmebedingungen' onClick='track(JetztGestalten)' class='underline text-blue' target='_blank'>Teilnahmebedingungen</a>."
								buttonUrl="close"
								buttonText="Okay"
							/>
						</div>
					</div>
				</section>
				<div className="mt-[27rem] md:mt-24">
					<UpScroll />
				</div>
			</section>
		</>
	)
}
export default CityName
