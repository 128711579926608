import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	onClick?: any
	showLeftCount?: boolean
	hasError?: boolean
	suffix?: string
}

const ElemInput: React.FC<InputProps> = ({ className, props, label, onClick, errMsg, showLeftCount, hasError, suffix }) => {
	return (
		<label className={"relative block mt-12" + (className || "")}>
			<input
				className={
					(props.type === "date" ? "" : " peer ") +
					" shadow-[inset_0_2px_2px_0_rgba(0,0,0,0.15)] w-full rounded-[24px] px-4 text-sm font-Manrope h-[40px] leading-[40px] placeholder-transparent outline-0 appearance-none bg-gray " +
					(hasError ? "  js-formerror border-2 border-red text-[#ff3f79]" : "text-white") +
					(props.type === "date" ? " uppercase" : "")
				}
				{...props}
				onClick={onClick}
			/>
			<span
				className={
					(props.type === "date" ? "" : " transition ") +
					(!!props.value ? "" : "") +
					"absolute left-0 -top-[35px] font-Museo font-bold text-sm h-[40px] leading-[40px] pointer-events-none "
					// (hasError ? "  text-red " : " ")
				}
			>
				{label} {props.required && "*"}
			</span>
			{suffix && <div className="absolute top-6 right-3">{suffix}</div>}
			{showLeftCount && props.maxLength && (
				<div className="absolute top-[11px] right-3 text-black text-sm">
					{props.value.length}/{props.maxLength}
				</div>
			)}
			{hasError && <div className="formErrorMsg font-bold text-[#ff3f79]">{errMsg}</div>}
		</label>
	)
}

export default ElemInput

// https://play.tailwindcss.com/tKldzVn3U1
