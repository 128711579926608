import { BrowserRouter, Route, Routes } from "react-router-dom"
import Error from "./nav/Error"
import Home from "./nav/Home"
import Store from "./store/store"
import Address from "./nav/Address"
import Footer from "./components/Footer"
import Configurator from "./nav/Configurator"
import Confirm from "./nav/Confirm"
import axios from "axios"
import { getEnvConfig } from "./env"
import { useEffect } from "react"
import Terms from "./nav/Terms"
import Privacy from "./nav/Privacy"
import Faq from "./nav/Faq"
import Partners from "./nav/Partners"
import Promoover from "./nav/Promoover"
import ConfirmWithoutCEP from "./nav/ConfirmWithoutCEP"
import SubmitCodes from "./components/codeInput/submitCodes"
import SubmitCodesAccount from "./components/codeInput/submitCodesAccount"
import Header from "./components/Header"
import Country from "./nav/Country"
import Promosoon from "./nav/Promosoon"
import Kontakt from "./nav/Kontakt"
import Imprint from "./nav/Imprint"

const App = () => {
	const env = getEnvConfig()
	const isPromoOver = parseInt(Math.floor(Date.now() / 1000).toString()) > 1716328800
	return (
		<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
			<Store>
				<Header />
				<main>
					<Routes>
						{isPromoOver ? (
							<>
								<Route path="/" element={<Promoover />} />
								<Route path="/kontakt" element={<Kontakt />} />
								<Route path="/teilnahmebedingungen" element={<Terms />} />
								<Route path="/datenschutz" element={<Privacy />} />
								<Route path="/faq" element={<Faq />} />
								<Route path="/impressum" element={<Imprint />} />
								<Route path="/:err" element={<Error />} />
							</>
						) : (
							<>
								<Route path="/" element={<Home />} />
								<Route path="/gestalten" element={<Configurator />} />
								<Route path="/promoover" element={<Promoover />} />
								<Route path="/promosoon" element={<Promosoon />} />
								<Route path="/danke" element={<ConfirmWithoutCEP />} />
								<Route path="/adresse" element={<Address />} />
								<Route path="/country" element={<Country />} />
								<Route path="/kontakt" element={<Kontakt />} />
								<Route path="/teilnahmebedingungen" element={<Terms />} />
								<Route path="/datenschutz" element={<Privacy />} />
								<Route path="/faq" element={<Faq />} />
								<Route path="/impressum" element={<Imprint />} />
								<Route path="/:err" element={<Error />} />
							</>
						)}
					</Routes>
				</main>
				<Footer />
			</Store>
		</BrowserRouter>
	)
}

export default App
