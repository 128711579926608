import React from "react"
import { Link } from "react-router-dom"
import { Parallax, ParallaxProvider } from "react-scroll-parallax"

const Promosoon: React.FC = () => {
	return (
		<>
			<ParallaxProvider>
				<>
					<section className="w-full h-[567px] bg-[url('/src/bgimages/knoppers/hero-bg.png')] bg-cover bg-center">
						<div className="max-w-[1000px] px-4 mx-auto ">
							<div className="grid grid-cols-[40%_50%_1fr] h-[567px] items-center justify-items-center relative">
								<div>
									<img
										className="absolute max-w-[250px] hidden lg:block lg:max-w-[470px] left-0 top-[50px]"
										src={process.env.PUBLIC_URL + "/images/knoppers/knoppers-stoerer.png"}
									></img>
									<img
										className="absolute max-w-[350px] w-[210px] block lg:hidden lg:max-w-[400px] left-[15px] top-[50px]"
										src={process.env.PUBLIC_URL + "/images/knoppers/stoerer-mobile.png"}
									></img>
								</div>
								<div className="self-end z-20 absolute lg:relative">
									<Parallax translateY={[-30, 0]}>
										<img
											className="translate-y-[0px] lg:translate-y-[140px] max-w-[310px] lg:max-w-full -translate-x-[15px] lg:-translate-x-[25px]"
											src={process.env.PUBLIC_URL + "/images/knoppers/tasse-lukas.png"}
										></img>
									</Parallax>
								</div>
								<div className=" ">
									<Parallax speed={10} translateY={[-20, 20]}>
										<img
											className="max-w-[230px] lg:max-w-[400px] ml-[110px] lg:-ml-[110px] mt-[25px] lg:-mt-[20px] z-[0]"
											src={process.env.PUBLIC_URL + "/images/knoppers/tasse-lena.png"}
										></img>
									</Parallax>
								</div>
							</div>
						</div>
					</section>
					<div className="knoppers-gradient lg:pb-19 pt-[50px] lg:pt-[120px] pb-[60px] lg:pb-[120px] ">
						<div className="px-4 max-w-[1200px] mx-auto lg:mb-32">
							<h2 className="h2 pt-0 lg:pt-6 text-darkblue font-FireSansCondensedExtraBold mb-4 lg:mb-12 text-[38px] lg:text-[50px]" id="sogehts">
								Diese Aktion beginnt bald.
							</h2>

							<p className="max-w-[77%] text-darkblue text-[20px] lg:text-[25px] font-FireSansMedium lg:font-FireSansBold mx-auto text-center mt-4 lg:mt-4 mb-6">
								Ab dem 06.02.2024 kannst Du hier Deine ganz persönliche Knoppers Tasse mit Deinem Wunschnamen gestalten.​
							</p>
							<div className="text-center">
								<p className="max-w-[85%] text-lightblue text-[18px] font-FireSansMedium mx-auto text-center my-4 lg:mt-12">
									Weitere Informationen findest Du in unseren{" "}
									<Link to="/teilnahmebedingungen" className="underline font-bold">
										Teilnahmebedingungen
									</Link>{" "}
									.​
								</p>
							</div>
						</div>
						<div className="blue-brush-border-bottom-rotate lg:mt-18 z-20"></div>
					</div>
				</>
			</ParallaxProvider>
		</>
	)
}
export default Promosoon
