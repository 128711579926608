import { useRef, useState, useContext } from "react"
import { Link } from "react-router-dom"
import { Parallax } from "react-scroll-parallax"

const Hero = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "Header", link: label })
		} catch (e) {}
	}
	return (
		<>
			<section className="w-full h-[567px] bg-[url('/src/bgimages/knoppers/hero-bg.png')] bg-cover bg-center">
				<div className="max-w-[1000px] px-4 mx-auto ">
					<div className="grid grid-cols-[40%_50%_1fr] h-[567px] items-center justify-items-center relative">
						<div>
							<img
								className="absolute max-w-[250px] hidden md:block md:max-w-[470px] left-0 top-[50px]"
								src={process.env.PUBLIC_URL + "/images/knoppers/knoppers-stoerer.png"}
							></img>
							<img
								className="absolute max-w-[350px] w-[210px] block md:hidden md:max-w-[400px] left-[15px] top-[50px]"
								src={process.env.PUBLIC_URL + "/images/knoppers/stoerer-mobile.png"}
							></img>
						</div>
						<div className="self-end z-20 absolute lg:relative">
							<img
								className="-translate-y-[38px] lg:translate-y-[80px] max-w-[275px] lg:max-w-full -translate-x-[35px] md:translate-x-[100px] lg:-translate-x-[25px]"
								src={process.env.PUBLIC_URL + "/images/knoppers/tasse-lukas.png"}
							></img>
						</div>
						<div className=" ">
							<img
								className="max-w-[202px] lg:max-w-[400px] ml-[125px] md:ml-[350px] lg:-ml-[110px] -mt-[110px] lg:-mt-[20px] z-[0]"
								src={process.env.PUBLIC_URL + "/images/knoppers/tasse-lena.png"}
							></img>
						</div>
					</div>
					<div className="btn-wrapper">
						<Link to="/gestalten" className="btn -mt-[50px] mx-auto translate-x-[40px] md:translate-x-[50px] md:-translate-y-[120px]" onClick={() => track("JetztGestalten")}>
							Jetzt gestalten!
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
export default Hero
